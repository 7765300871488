<template>
  <v-menu
    :model-value="menuExpanded"
    data-cy="table-actions-button-menu"
    transition="slide-y-transition"
  >
    <template #activator="{ props }">
      <v-btn
        :class="'table-actions-btn' + ' ' + buttonStyleClass"
        variant="flat"
        v-bind="props"
        data-cy="table-actions-button"
      >
        <v-icon v-if="lightningIcon" start>mdi-flash-outline</v-icon>
        {{ $t(text) }}
        <v-icon end :class="menuExpanded ? 'turn-180' : ''">
          mdi-menu-down
        </v-icon>
      </v-btn>
    </template>
    <ActionButtonList :config="configWithEnabledActions" :item="item" />
  </v-menu>
</template>

<script lang="ts">
import { eventBus } from '@/main';
import { defineComponent } from 'vue';
import {
  ActionsButtonWithCallback,
  ActionTypes,
  ButtonCallbacks
} from '../../types/DataTable';
import ActionButtonList from './ActionButtonList.vue';

export default defineComponent({
  name: 'DataTableActionButton',
  components: {
    ActionButtonList
  },

  props: {
    config: {
      type: Array as () => ActionsButtonWithCallback[],
      required: true
    },
    item: {
      type: Object as () => Record<string, unknown>,
      required: false
    },
    lightningIcon: {
      type: Boolean,
      default: true
    },
    text: {
      type: String,
      default: 'common.actions'
    },
    reactsOnEventBus: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menuExpanded: false,
      mutateableConfig: undefined as ActionsButtonWithCallback[] | undefined,
      selectionType: ActionTypes.NONE
    };
  },

  computed: {
    buttonStyleClass(): string {
      switch (this.selectionType) {
        case ActionTypes.SINGLE:
          return 'single-selection-button-style';
        case ActionTypes.MULTIPLE:
          return 'multi-selection-button-style';
        default:
          return '';
      }
    },
    filteredActionsButtonConfig(): ActionsButtonWithCallback[] {
      return (this.mutateableConfig ?? this.config).filter((actionButton) =>
        actionButton.types.includes(this.selectionType)
      );
    },
    configWithEnabledActions() {
      return (
        this.filteredActionsButtonConfig.filter(
          (action) => !!action.disabled === false
        ) ?? []
      );
    }
  },
  created() {
    if (this.reactsOnEventBus) {
      eventBus.$on(
        'updateActionsButton',
        (data: ActionsButtonWithCallback[], selectionType?: ActionTypes) => {
          this.mutateableConfig = data;
          this.selectionType = selectionType ?? ActionTypes.NONE;
        }
      );
    }
  },
  unmounted() {
    eventBus.$off('updateActionsButton');
  },
  methods: {
    isAction(action: ActionsButtonWithCallback): boolean {
      return action.id !== ButtonCallbacks.NONE;
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.table-actions-btn {
  border-radius: 6px;
  background-color: $table-header !important;
  color: $secondary;
  text-transform: none;
  transition: all 0.25s ease-in-out;
}
.v-btn::before {
  color: $light-shadow;
}

.turn-180 {
  transform: rotate(180deg);
}
.v-menu__content {
  box-shadow: 0 0.1 0 0.1 !important;
}
.single-selection-button-style {
  background-color: $primary-50 !important;
  color: black !important;
  transition: all 0.25s ease-in-out;
}

.multi-selection-button-style {
  background-color: $primary !important;
  transition: all 0.25s ease-in-out;
  color: white !important;
}
.action-list {
  background-color: $table-header;
}
</style>
