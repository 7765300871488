<template>
  <v-dialog
    v-if="show"
    id="small-dialog"
    v-model="show"
    persistent
    :width="dialogWidth"
  >
    <v-card rounded="0" data-cy="dialog-card">
      <v-toolbar flat density="compact" color="mediumShadow">
        <v-toolbar-title
          id="dialog-toolbar-title"
          data-cy="dialog-toolbar-title"
        >
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip location="top" open-delay="100">
          <template #activator="{ props }">
            <v-btn
              id="small-dialog-close-button"
              data-cy="small-dialog-close-button"
              :disabled="loading"
              icon
              v-bind="props"
              @click="close"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('common.cancel') }}</span>
        </v-tooltip>
      </v-toolbar>
      <slot />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface DataType {
  show: boolean;
}

export default defineComponent({
  name: 'DialogSmall',
  props: {
    title: String,
    dialogWidth: String,
    loading: Boolean,
    onClose: Function
  },
  data(): DataType {
    return {
      show: true
    };
  },
  methods: {
    close(): void {
      if (this.onClose) this.onClose();
      this.show = false;
    }
  }
});
</script>
