<template>
  <div class="container">
    <v-text-field
      v-model="search"
      :label="$t('common.search')"
      hide-details
      prepend-inner-icon="mdi-magnify"
      density="compact"
      variant="solo"
      :bg-color="colors.tableHeader"
      flat
      clearable
      class="searchbar mr-2"
      data-cy="table-searchbar"
      @update:model-value="$emit('update:search', search)"
    />
    <IconButton
      v-if="!$attrs['no-filter']"
      :config="buttonConfig()"
      :active="filter"
    />
  </div>
</template>

<script lang="ts">
import IconButton from '@/components/common/table/IconButton.vue';
import { ButtonCallbacks } from '@/components/types/DataTable';
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
export default defineComponent({
  name: 'DataTableSearchbar',
  components: {
    IconButton
  },
  emits: ['update:filter', 'update:search'],
  data() {
    return {
      search: '',
      filter: false,
      colors
    };
  },
  methods: {
    updateFilter() {
      this.filter = !this.filter;
      this.$emit('update:filter', this.filter);
    },
    buttonConfig() {
      return {
        id: ButtonCallbacks.FILTER,
        icon: 'mdi-filter',
        title: 'common.filter',
        callback: this.updateFilter.bind(this)
      };
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';
.container {
  display: flex;
  flex-direction: row;
  min-width: 35%;
  justify-content: center !important;
  align-items: center !important;
}
.searchbar {
  min-width: 40px;
  border-radius: 5px;
}
</style>
