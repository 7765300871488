/* eslint-disable max-len */
import { i18n } from './plugins/i18n';
export const rules = {
  ruleMinCharacters: (value: string, minChars: number) =>
    !value ||
    (value && value.length >= minChars) ||
    i18n.t('validationError.minChar', { minValue: minChars.toString() }),
  ruleMaxCharacters: (value: string, maxChars: number) =>
    !value ||
    (value && value.length <= maxChars) ||
    i18n.t('validationError.maxChar', { maxValue: maxChars.toString() }),
  ruleEmptyOrStringNumberDotsUnderscore: (value: string) => {
    const pattern = /^($|[A-Za-z0-9_.-]+)*$/; // empty string , numbers, dots, minus, underscore
    return pattern.test(value) || i18n.tc('validationError.chars').toString();
  },
  ruleNumberWithLengthBetween4and8OrEmpty: (value: string) => {
    const pattern = /^($|[0-9]{4,8})$/; // empty string or number with length between 4 and 8
    return (
      pattern.test(value) || i18n.tc('validationError.pinLength').toString()
    );
  },
  required: (value: string) => !!value || i18n.tc('validationError.required'),
  ruleEmptyOrIpv4Address: (value: string) => {
    const pattern =
      /^($|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)))$/;
    return pattern.test(value) || i18n.t('validationError.ipv4');
  },
  ruleMinMaxNumber: (value: string, minNum: number, maxNum: number) =>
    !value || (value && Number(value) >= minNum && Number(value) <= maxNum),
  ruleNetMask: (value: string) =>
    rules.ruleMinMaxNumber(value, 0, 32) || i18n.t('validationError.netmask'),

  ruleJsonConfirm: (value: string) => {
    const pattern = /[\\"]/; // Search for Chars that escape name of JSON-Attribute
    return !pattern.test(value) || i18n.t('settings.nameRulesJSON');
  },
  ruleNoSpecialCharacters: (v: string) =>
    /^[a-zA-Z0-9_.-]+$/.test(v) ||
    i18n.t('resources.fileNameRulesNoSpecialCharacters'),
  ruleNoDotsInFront: (v: string) =>
    /^[^.].*$/.test(v) || i18n.t('resources.fileNameRulesDots'),
  ruleUrlConform: (v: string) =>
    /^[a-zA-Z0-9_-]*$/g.test(v) ||
    i18n.t('validationError.urlConform').toString(),
  ruleNoSpecialCharsBeginning: (v: string) =>
    /^[a-zA-Z0-9].*$/.test(v) ||
    i18n.t('validationError.urlConform').toString(),
  ruleVariableName: (v: string) =>
    /^[a-zA-Z0-9_]*$/g.test(v) ||
    i18n.t('templates.keyNameRulesSpecialSigns').toString(),

  ruleEmptyOrDotSeparatedDecimal: (v: string) =>
    /(^$)|(^-?\d+\.\d+$)/.test(v ?? '') ||
    i18n.t('validationError.dotSeperatedDecimal').toString()
};
