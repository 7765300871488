/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { DeviceGroup } from '../../store/types/inventory/Device';

export default {
  find() {
    return Api.get('inventory/device-groups');
  },
  async getById(id: string): Promise<DeviceGroup> {
    const response = await Api.get(`inventory/device-groups/${id}`);
    return response.data;
  },
  create(groupName: string) {
    const nameObject = {
      name: groupName
    };
    return Api.post('inventory/device-groups', nameObject);
  },
  async delete(uuid: string): Promise<DeviceGroup> {
    return (await Api.delete(`inventory/device-groups/${uuid}`)).data;
  },
  async rename(uuid: string, newName: string): Promise<DeviceGroup> {
    const response = await Api.put(`inventory/device-groups/${uuid}`, { name: newName });
    return response.data;
  }
};
