<template>
  <ExtendedDialog
    :show-dialog="show"
    :process-action="processing"
    :config="addDeviceDialog"
    :action-flag="addAnotherRouterFlag"
    :show-action-flag="true"
    @execute-action="execute($event)"
    @update:action-flag="setAddAnotherRouterFlag"
  >
    <v-form
      ref="form"
      :disabled="processing"
      class="dialog-content"
      :validate-on="validationType"
    >
      <span class="grey-text hint">{{ $t('common.requiredField') }}</span>
      <v-text-field
        ref="inputName"
        v-model="name"
        @keydown.enter.prevent="createDevice"
        autofocus
        class="mt-3"
        :rules="nameRules"
        :label="
          $t('devices.deviceName')
            .toString()
            .concat($t('common.requiredSign').toString())
        "
        variant="outlined"
        clearable
        density="compact"
      />
      <v-text-field
        v-model="serialNumber"
        class="short"
        :rules="serialNumberRules"
        @keydown.enter.prevent="createDevice"
        :label="
          $t('devices.serialnumber')
            .toString()
            .concat($t('common.requiredSign').toString())
        "
        variant="outlined"
        clearable
        density="compact"
        counter="25"
      />
      <v-text-field
        v-model="location"
        :label="$t('devices.location')"
        @keydown.enter.prevent="createDevice"
        variant="outlined"
        clearable
        density="compact"
      />
      <GroupSelector
        v-model:group="group"
        class="short"
        :shouldFetchGroups="false"
      />
    </v-form>
  </ExtendedDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { ButtonCallbacks } from '@/components/types/Dialog';
import { addDeviceDialog } from '@/models/inventory/deviceDialogConfig';
import ExtendedDialog from '@/components/common/dialog/ExtendedDialog.vue';
import { deviceStore } from '@/store/pinia/DeviceStore';
import { Device } from '@/store/types';
import GroupSelector from '@/components/common/GroupSelector.vue';
import { i18n } from '@/plugins/i18n';
import { VALIDATION_TYPE } from '@/shared/constants';

export default defineComponent({
  name: 'AddDeviceDialog',
  components: { ExtendedDialog, GroupSelector },
  emits: ['update:showDialog'],
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      addDeviceDialog,
      name: '',
      serialNumber: '',
      location: '',
      group: '',
      processing: false,
      validationType: VALIDATION_TYPE.SUBMIT
    };
  },

  mounted() {
    void this.fetchDevices();
  },
  computed: {
    ...mapState(deviceStore, [
      'devices',
      'addAnotherRouterFlag',
      'getDeviceByName'
    ]),
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    },
    serialNumberRules() {
      return [
        (v: string) => !!v || i18n.t('devices.serialNumberRuleRequired'),
        (v: string) =>
          !this.devices
            .map((device: Device) => device.serialNumber)
            .includes(v) || i18n.t('devices.serialNumberRuleRequiredUnique')
      ];
    },
    nameRules() {
      return [
        (v: string) => !!v || i18n.t('devices.nameRuleRequired'),
        (v: string) =>
          !this.devices.map((device: Device) => device.name).includes(v) ||
          i18n.t('devices.nameRuleRequiredUnique')
      ];
    }
  },
  methods: {
    ...mapActions(deviceStore, [
      'fetchDevices',
      'addDevice',
      'setAddAnotherRouterFlag'
    ]),

    async execute(callbackID) {
      if (callbackID === ButtonCallbacks.ADD) {
        await this.createDevice();
      } else {
        this.closeDialog();
      }
    },

    async createDevice() {
      const { valid } = await this.$refs.form.validate();
      this.validationType = VALIDATION_TYPE.INPUT;
      if (valid) {
        this.processing = true;
        const device = {
          name: this.name,
          serialNumber: this.serialNumber,
          location: this.location,
          groupUuid: this.group
        };
        await this.addDevice(device);
        this.processing = false;
        if (!this.addAnotherRouterFlag) this.resetDialog();
        this.fetchDevices().finally(() => {
          if (!this.addAnotherRouterFlag) this.showDetails(device.name);
        });
        this.closeDialog();
      }
    },
    closeDialog() {
      this.resetDialog();
      this.setAddAnotherRouterFlag(false);
      this.$emit('update:showDialog', false);
    },
    resetDialog() {
      this.validationType = VALIDATION_TYPE.SUBMIT;
      this.processing = false;
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
    showDetails(name: string): void {
      const device = this.getDeviceByName(name);
      if (device) {
        void this.$router.push({
          name: 'device-details',
          params: { uuid: device.uuid }
        });
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.short {
  width: 40vh;
}
.hint {
  font-size: 14px;
}
</style>
