/* eslint-disable @typescript-eslint/naming-convention */
import { License } from '@/models/licenses/licenseTypes';
import { AccountTierLevel } from '../admin/index';

export interface Organisation extends Record<string, unknown> {
  uuid: string;
  category: string;
  createdAt?: string;
  updatedAt?: string;
  address1?: string;
  address2?: string;
  city?: string;
  countryCode: string;
  customerNumber?: string;
  emailAddress?: string;
  name: string;
  phoneNumber?: string;
  postalCode?: string;
  roles?: string[];
  state?: string;
  supplierName?: string;
  supplierUuid?: string;
  validated: boolean;
  vatNumber?: string;
}

export interface AccountMembershipType {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  accountUuid: string;
  userUuid: string;
  isHomeAccount: boolean;
}

export interface CredentialType {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  passwordSetAt: string;
  locked: boolean;
  userUuid: string;
  lastLoginAt: string;
  lastLogoutAt: string;
  userInfoLastLoginAt: string;
  userInfoLastLoginFailedAt: string;
  userInfoLastLoginFailedCount: string;
}

export interface User extends Record<string, unknown> {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  confirmed: boolean;
  emailAddress: string;
  firstName: string;
  lastName: string;
  middleName: string;
  organisationUuid: string;
  organisation?: Organisation;
  phoneNumber: string;
  preferredLanguage: string;
  registeredAt: string;
  salutation: string;
  title: string;
  username: string;
  accountMemberships: AccountMembershipType[];
  credential: CredentialType;
  release: ReleaseType;
}

export interface ReleaseType {
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  version: string;
  releaseNotesUrl: string;
  releaseDate: string;
}

export interface UserFields {
  [key: string]: Record<number | string | symbol, unknown>;
}

export interface UserFieldTableHeader {
  title: string;
  value: string;
  filter?: FilterFunction;
  divider?: boolean;
  sortable?: boolean;
  align?: string;
}

type FilterFunction = (value: string) => boolean;

export interface Account extends Record<string, unknown>{
  category: string;
  type: string;
  uuid: string;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  organisationUuid: string;
  organisation?: Organisation;
  accountMemberships?: AccountMembershipType[];
  namespace?: string;
  activeLicenses?: License[];
  internal?: boolean;
  userFields?: UserFields;
  tierLevel?: AccountTierLevel;
}

export interface CustomFieldType extends Record<string, unknown>{
  label?: string;
  name: string;
  valueType: string;
  value?: string | number | boolean;
}

export interface SystemSettingsStateType {
  settings: Setting[];
  settingsTypes: Record<string, string>;
  customFields: CustomFieldType[];
  selectedCustomFields: CustomFieldType[];
}

export interface SystemSettingsActionType {
  commit: (mutation: string, payload?: any) => {};
  dispatch: (action: string, payload?: any) => Promise<any>;
  state: SystemSettingsStateType;
}

export interface Setting {
  name: string;
  value: unknown;
}

// eslint-disable-next-line no-shadow
export enum OrganisationRole {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER',
  BROKER = 'BROKER',
  RESELLER = 'RESELLER',
  PLATFORM_OPERATOR = 'PLATFORM_OPERATOR'
}
