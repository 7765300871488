import { NavigationMenu } from '../../../navigationDrawer/navigationDrawer';

export const settingsMenu: NavigationMenu = {
  items: [
    {
      title: 'settings.customField',
      icon: 'mdi-file-edit',
      path: '/settings/custom-fields',
      featureFlags: 'customFieldsEnabled'
    },
    {
      title: 'settings.apiToken',
      icon: 'mdi-shield-key-outline',
      path: '/settings/api-token',
      featureFlags: 'apiEnabled'
    }
  ],
  title: 'routes.settings',
  icon: 'mdi-cog'
};

export const userMenu = (logoutCallback: () => void) => ({
  items: [
    {
      title: 'app.showProfile',
      icon: 'mdi-badge-account-horizontal-outline',
      path: '/user-menu/profile'
    },
    {
      title: 'app.showAccountSettings',
      icon: 'mdi-account-cog',
      path: '/user-menu/account-settings'
    },
    {
      title: 'settings.licenses',
      icon: 'mdi-list-box-outline',
      path: '/user-menu/licenses'
    },
    {
      title: 'app.logout',
      icon: 'mdi-logout',
      callback: logoutCallback
    }
  ],
  title: 'app.userMenu',
  icon: 'mdi-account'
});
