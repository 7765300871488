<template>
  <UploadDialog
    :direct-upload="true"
    :show-dialog="show"
    :config="importUpdatePackagesDialog"
    :processing="processing"
    :multiple="true"
    :file-types="fileTypes"
    @execute-action="execute"
    @add-files="uploadUpdatePackages"
  />
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { importUpdatePackagesDialog } from '@/models/updatePackages/updatePackagesDialogConfig';
import UploadDialog from '@/components/common/dialog/UploadDialog.vue';
import { showErrorSnackbar } from '@/util/snackBarUtil';

export default defineComponent({
  name: 'ImportUpdatePackagesDialog',
  components: { UploadDialog },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:showDialog'],
  data() {
    return {
      importUpdatePackagesDialog,
      processing: false,
      fileTypes: 'application/x-tar, .tar'
    };
  },

  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    }
  },
  methods: {
    ...mapActions({
      selectFiles: 'updatePackages/selectFiles',
      uploadUpdatePackets: 'updatePackages/uploadUpdatePackets',
      load: 'updatePackages/loadUpdatePackets'
    }),
    execute() {
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
    async uploadUpdatePackages(files: FileList) {
      if (files.length > 0) {
        const incorrectFiles = Array.from(files).filter(
          (file) => file.type !== 'application/x-tar'
        );
        if (incorrectFiles.length > 0) {
          Array.from(incorrectFiles).forEach((file) => {
            const fileName = file.name ? file.name : file.type;
            showErrorSnackbar(
              this.$t('updatePackages.dialogShowErrorSnackbar', {
                msg: fileName
              }).toString()
            );
          });
        }
        this.processing = true;
        await this.selectFiles(
          Array.from(files).filter((file) => file.type === 'application/x-tar')
        );
        await this.uploadUpdatePackets();
        await this.load();
        this.processing = false;
        this.closeDialog();
      }
    }
  }
});
</script>
