<template>
  <v-navigation-drawer
    v-if="loggedIn"
    mobile-breakpoint="0"
    rail-width="48"
    :color="colors.navigation"
    permanent
    class="bg-white"
    :rail="!expandNavigationDrawer"
  >
    <v-list>
      <NavigationGroup :navigation-items="navigationDrawerConfig" />
    </v-list>
    <template #append>
      <v-row
        no-gutters
        justify="end"
        align="center"
        class="border-eight bg-white"
      >
        <div class="feedback ml-3 pointer-cursor">
          <a
            v-if="expandNavigationDrawer"
            data-cy="feedback-button"
            class="text-blue"
            @click="openFeedbackDialog"
          >
            <v-icon size="small" color="primary" class="mr-2 pointer-cursor"
              >mdi-message-processing
            </v-icon>
            {{ $t('app.giveFeedback') }}</a
          >
        </div>
        <v-spacer></v-spacer>
        <v-btn
          id="app-navigation-toggle"
          size="small"
          class="ma-0 pa-0"
          variant="text"
          icon
          @click="expandNavigationDrawer = !expandNavigationDrawer"
        >
          <v-icon v-if="expandNavigationDrawer">mdi-chevron-double-left</v-icon>
          <v-icon v-if="!expandNavigationDrawer"
            >mdi-chevron-double-right</v-icon
          >
        </v-btn>
      </v-row>
    </template>
    <FeedbackDialog
      :show-dialog="showDialog"
      @update:show-dialog="showDialog = false"
    />
  </v-navigation-drawer>
</template>
<script>
import NavigationGroup from './NavigationGroup.vue';
import { mapState, mapActions } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';
import { navigationDrawerConfig } from '../../../navigationDrawer/navigationDrawer';
import { colors } from '@/styles/colors';
import FeedbackDialog from '@/components/app/appBar/FeedbackDialog.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { NavigationGroup, FeedbackDialog },

  data: () => ({
    expandNavigationDrawer: true,
    width: '16.875em',
    colors,
    showDialog: false,
    navigationDrawerConfig
  }),
  methods: {
    ...mapActions(authenticationStore, ['resetNavDrawerGroupStates']),

    changeExpandNavigationDrawer() {
      this.expandNavigationDrawer = !this.expandNavigationDrawer;
      void this.resetNavDrawerGroupStates();
    },
    openFeedbackDialog() {
      this.showDialog = true;
    }
  },
  computed: {
    ...mapState(authenticationStore, ['loggedIn']),
    navDrawerConfig() {
      return navigationDrawerConfig;
    }
  }
});
</script>
<style lang="scss" scoped>
.v-list-group--prepend {
  --parent-padding: var(--prepend-width);
}
.bg-white {
  background-color: white !important;
}
.button-row {
  flex: 0;
  align-self: flex-end;
  justify-self: end;
}
.v-list {
  flex: 1;
}
.feedback {
  font-size: 14px;
  display: flex !important;
}
</style>
