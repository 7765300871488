<template>
  <v-data-table-virtual
    :id="config.tableId"
    v-bind="{ ...$attrs, ...$props }"
    v-model:model-value="selected"
    :items="filteredItems"
    :item-value="itemKey"
    :headers="enrichedHeaders"
    :height="calcHeight"
    fixed-header
    return-object
    select-strategy="all"
    :loading-text="$t('common.loading')"
    :row-props="rowProps"
    @click:row="handleRowClick"
  >
    <template #top>
      <DataTableToolbar
        v-if="config.toolbarConfig"
        :config="config.toolbarConfig"
        :current-count="currentCount"
        :max-count="maxCount"
        @update:filter="updateFilter"
        @update:search="updateSearch"
      />
      <FilterRow
        v-show="!!filter"
        :id="filterRowId"
        :mount="!!filter"
        v-bind="$attrs"
        :items="items"
        :enriched-headers="enrichedHeaders"
        @collect-filter-values="collectFilterValues"
      />
    </template>
    <template #no-data>
      <NoData
        v-if="config.noDataConfig && !search && !filter"
        :config="config.noDataConfig"
      />
      <span v-else-if="search || filter">
        {{ $t('common.noMatchingData') }}</span
      >
      <span v-else> {{ $t('common.noData') }}</span>
    </template>
    <!-- pass through normal slots-->
    <template v-for="(_, slotName) in $slots" #[slotName]="slotData">
      <slot :name="slotName" v-bind="slotData" />
    </template>

    <!-- toggleable appended column -->
    <template v-if="$attrs['three-dot-menu']" #item.three-dot-menu="{ item }">
      <ThreeDotMenu
        :config="config.toolbarConfig?.actionsButtonConfig ?? []"
        :item="item"
      />
    </template>
  </v-data-table-virtual>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { eventBus } from '@/main';
import {
  DataTableHeader,
  DataTable,
  THREE_DOT_MENU
} from '@/components/types/DataTable';
import FilterRow from './table/FilterRow.vue';
import {
  filterItems,
  transformItemValues,
  returnFilterValues
} from '@/util/dataTable';
import NoData from './table/NoData.vue';
import ThreeDotMenu from './table/ThreeDotMenu.vue';
import DataTableToolbar from './table/DataTableToolbar.vue';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

export default defineComponent({
  name: 'ExtendedDataTable',
  components: { NoData, ThreeDotMenu, DataTableToolbar, FilterRow },
  props: {
    items: {
      type: Array as () => Record<string, unknown>[],
      required: true
    },
    config: {
      type: Object as () => DataTable,
      default: () => ({})
    },
    headers: {
      type: Array as () => DataTableHeader[],
      required: true
    },
    itemKey: {
      type: String,
      required: false,
      default: 'uuid'
    },
    customHeight: {
      type: String,
      default: ''
    },
    additionalSearchHeaderKeys: {
      type: Array<string>,
      default: () => []
    }
  },
  emits: ['update:selected'],
  data() {
    return {
      headerAndToolbarOffset: 170, // appbar 40 + toolbar 48 + padding 8 + tableHeader 66
      rowHeight: 62,
      filter: false,
      search: '',
      filterValues: {},
      REMOVE_SCROLLBAR_OFFSET: 2,
      THREE_DOT_MENU,
      transformedItemsForFiltering: [] as Record<string, unknown>[],
      selected: [] as Record<string, unknown>[],
      returnFilterValues,
      filterRowId: this.config.tableId + '-filterRow'
    };
  },

  computed: {
    ...mapState(authenticationStore, ['numberOfAppBars']),
    calcHeight() {
      // TODO add eventListener when window size changes
      // https://www.geeksforgeeks.org/how-to-monitor-changing-window-sizes-in-vuejs/
      if (this.customHeight !== '' && this.filteredItems.length !== 0) {
        return this.customHeight;
      } else {
        return this.filteredItems.length === 0
          ? undefined
          : window.innerHeight -
              this.headerAndToolbarOffset -
              this.numberOfAppBars * 40;
      }
    },
    enrichedHeaders(): DataTableHeader[] {
      this.headers.forEach((header) => {
        // If no sortable is set, set it to true
        if (header.sortable === undefined) header.sortable = true;
      });
      return this.$attrs['three-dot-menu']
        ? [
            ...this.headers,
            ...[
              {
                title: '',
                value: 'three-dot-menu',
                sortable: false,
                width: 24
              } as DataTableHeader
            ]
          ]
        : this.headers;
    },
    currentCount() {
      return this.filteredItems.length;
    },
    maxCount() {
      return this.items.length;
    },

    filteredItems(): unknown[] {
      if (this.transformedItemsForFiltering.length === 0) {
        this.transformItems(this.items);
      }
      return filterItems(
        [...this.items],
        this.transformedItemsForFiltering,
        this.itemKey,
        this.search,
        this.headers,
        this.filterValues,
        this.additionalSearchHeaderKeys
      );
    }
  },
  watch: {
    filter(newFilter) {
      void this.$nextTick(() => {
        if (!newFilter) {
          this.filterValues = {};
        }
      });
    },
    items(newItems: Record<string, unknown>[]) {
      this.transformItems(newItems);
    },
    selected() {
      this.$emit('update:selected', this.selected);
    }
  },

  mounted() {
    this.moveFilterRowIntoHeader();
    eventBus.$on(
      'updateSelectedItemsOfExtendedDataTable',
      (tableId: string, selectedItems: Record<string, unknown>[]) => {
        if (tableId === this.config.tableId) this.selected = selectedItems;
      }
    );
  },

  unmounted() {
    eventBus.$off('updateSelectedItemsOfExtendedDataTable');
  },
  methods: {
    rowProps(data) {
      if (
        !this.$attrs['no-select'] &&
        this.selected
          .flatMap((item) => item[this.itemKey])
          .includes(data.item[this.itemKey])
      ) {
        return {
          class: 'selected-row'
        };
      } else {
        return {
          class: 'hover-color'
        };
      }
    },
    handleRowClick(_, row) {
      this.$attrs['select-strategy'] === 'single'
        ? this.clickRowSingle(row)
        : this.clickRow(row);
    },
    clickRow(row) {
      const rowIndex = this.selected.findIndex(
        (item) => item[this.itemKey] === row.item[this.itemKey]
      );
      if (rowIndex > -1) {
        this.selected.splice(rowIndex, 1);
      } else {
        this.selected.push(row.item);
      }
      this.$emit('update:selected', this.selected);
    },
    clickRowSingle(row) {
      if (this.selected[0] === row.item) this.selected = [];
      else this.selected = [row.item];
      this.$emit('update:selected', this.selected);
    },
    transformItems(rawItems: Record<string, unknown>[]) {
      if (rawItems.length !== 0 && !this.$attrs.loading) {
        this.transformedItemsForFiltering = [];
        this.transformedItemsForFiltering = transformItemValues(
          [...rawItems],
          this.headers
        );
      }
    },
    updateFilter(filter: boolean): void {
      this.filter = filter;
    },
    updateSearch(search: string): void {
      this.search = search;
    },
    collectFilterValues(header: string, values: string[]): void {
      this.filterValues = {
        ...this.filterValues,
        [header]: values
      };
    },
    moveFilterRowIntoHeader() {
      // Directly select the specific table's header using the table ID
      const explicitHeader = document.querySelector(
        `#${this.config.tableId} thead`
      );
      const meRow = document.getElementById(this.filterRowId);
      if (meRow && explicitHeader) explicitHeader.appendChild(meRow);
    }
  }
});
</script>

<style lang="scss">
@import '@/styles/colors.scss';
.selected-row {
  background: $selected-row !important;
}
.hover-color:hover {
  background: $greyish-table-background !important;
}

.hidden-overflow {
  overflow: hidden !important;
}
</style>
