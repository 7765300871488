import { VNode } from 'vue';

export const directives = {
  uppercase: {
    bind: (el: HTMLElement, _: unknown, vnode: VNode) => {
      const uppercaseEventListener = (elem: HTMLInputElement) => () => {
        const start = elem.selectionStart;
        elem.value = elem.value.toUpperCase();
        elem.setSelectionRange(start || 0, start || 0);
        vnode.componentInstance?.$emit('input', elem.value.toUpperCase());
      };
      if (el instanceof HTMLInputElement) {
        el.addEventListener('input', uppercaseEventListener(el));
        return;
      } else if (el.children?.length > 0) {
        let directiveApplied = false;
        const applyToInputChildren = (elem: Element) => {
          if (elem instanceof HTMLInputElement) {
            directiveApplied = true;
            elem.addEventListener('input', uppercaseEventListener(elem));
          }
          if (elem.children?.length > 0) {
            [...elem.children].forEach(applyToInputChildren);
          }
        };
        [...el.children].forEach(applyToInputChildren);
        if (directiveApplied) {
          return;
        }
      }
      // eslint-disable-next-line no-console
      console.warn(
        '\'uppercase\' directive used on non-input element or element without input children'
      );
    }
  }
} as const;
