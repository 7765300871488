<template>
  <DialogSmall
    ref="dialog"
    :title="$t('devices.downloadFailure').toString"
    dialog-width="90vh"
  >
    <v-container class="pa-4">
      <v-flex class="pa-2 text-body-2">
        <span>
          <v-alert variant="text" density="compact" type="warning"
            >{{ $t('devices.alertConflictSystemSettings') }}
          </v-alert>
          {{ $t('devices.downloadFailureText') }}
        </span>
      </v-flex>
    </v-container>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        variant="flat"
        color="primary"
        class="text-none mr-2 pl-4 pr-4"
        @click="showSystemSettings"
        >{{ $t('devices.toSystemSettings') }}</v-btn
      >
      <v-btn
        id="next-steps-confirmation-button"
        variant="flat"
        color="primary"
        class="text-none mx-1 mr-4"
        @click="close()"
        >{{ $t('common.close') }}
      </v-btn>
    </v-card-actions>
  </DialogSmall>
</template>

<script lang="ts">
import { defineComponent, App } from 'vue';

import DialogSmall from '@/components/common/DialogSmall.vue';

export default defineComponent({
  name: 'DownloadConfigFailureDialog',
  components: { DialogSmall },

  emits: ['click'],
  data() {
    return {};
  },
  computed: {
    dialog(): App & { close: () => void } {
      return this.$refs.dialog as App & { close: () => void };
    }
  },
  methods: {
    close(): void {
      this.dialog.close();
    },
    showSystemSettings(): void {
      this.$router.push('/administration/system-settings');
      this.close();
    }
  }
});
</script>
