<template>
  <div>
    <v-autocomplete
      v-model="selectedGroup"
      :items="groups"
      item-title="name"
      item-value="uuid"
      :label="$t('devices.deviceGroup')"
      variant="outlined"
      density="compact"
      :disabled="disabled"
      hide-details
    >
      <template #prepend-item>
        <v-list-item
          class="insys-blue"
          :title="$t('devices.addGroup')"
          @click="openAddGroupDialog"
        />
        <v-divider />
      </template>
    </v-autocomplete>
    <AddGroupDialog
      v-if="showAddGroupDialog"
      :showDialog="showAddGroupDialog"
      @update:showDialog="showAddGroupDialog = false"
      @setNewGroup="setNewGroup"
    />
  </div>
</template>

<script lang="ts">
import { deviceStore } from '@/store/pinia/DeviceStore';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { i18n } from '@/plugins/i18n';
import AddGroupDialog from '@/components/devices/dialogs/AddGroupDialog.vue';

const DEFAULT_GROUP = 'Standardgruppe';

export default defineComponent({
  name: 'GroupSelector',
  components: { AddGroupDialog },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    group: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shouldFetchGroups: {
      type: Boolean,
      default: true
    }
  },

  emits: ['update:group'],

  data() {
    return {
      addNewGroup: i18n.t('devices.addGroup').toString(),
      showAddGroupDialog: false
    };
  },
  mounted() {
    // e.g. if devices are definitely being fetched before, no need to refetch groups, as they are already available
    if (this.shouldFetchGroups) {
      this.fetchGroups();
    }
    this.selectedGroup = this.editMode
      ? this.group
      : this.groups.find((g) => g.name === DEFAULT_GROUP)?.uuid ??
        this.groups[0]?.uuid;
  },
  computed: {
    ...mapState(deviceStore, ['groups', 'getGroupByName']),
    selectedGroup: {
      get() {
        return this.group;
      },
      set(value) {
        if (this.group !== value) {
          this.$emit('update:group', value);
        }
      }
    }
  },

  methods: {
    ...mapActions(deviceStore, ['fetchGroups']),
    extendedGroups() {
      const extendedGroups: object[] = [];
      extendedGroups.push({ name: this.addNewGroup });
      extendedGroups.push({ divider: true });
      this.groups.forEach((g) =>
        extendedGroups.push({ name: g.name, uuid: g.uuid })
      );
      return extendedGroups;
    },
    openAddGroupDialog(event) {
      if (event === this.addNewGroup) {
        this.showAddGroupDialog = true;
      }
    },
    setNewGroup(newGroup: string) {
      this.selectedGroup = this.getGroupByName(newGroup)?.uuid ?? '0';
    }
  }
});
</script>
