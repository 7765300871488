import { i18n } from '@/plugins/i18n';
import { snackbarStore } from '@/store/pinia/SnackbarStore';
import { hasTruthyField } from '.';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

export const showSnackbar = (text: string, timeout = 6000): void => {

  snackbarStore().addSnackbarItem({
    text,
    timeout,
    error: false
  });

};

export const showErrorSnackbar = (text: string, timeout = -1): void => {

  snackbarStore().addSnackbarItem({
    text,
    timeout,
    error: true
  });

  const authStore = authenticationStore();
  const loggedInUser = authStore.loggedInUser;

  if (loggedInUser.uuid) {
    instance.$mount(); // pass nothing
    const element = document.getElementById('app');
    element?.appendChild(instance.$el);
  }
};


export const showApiErrorSnackbar = (text: string, error: any): void => {
  const errorCode = error.response?.status;
  switch (errorCode) {
    case 400:
      showErrorSnackbar(text);
      break;
    case 401:
      showErrorSnackbar(text);
      break;
    case 404:
      showErrorSnackbar(text);
      break;
    default: // do NOT show any snackbar!!!
      break;
  }
};

export const createErrorMessage = (
  error:
  | {
    data: { message: string };
    response: { data: { message: string } };
  }
  | Error
  | unknown
  | string
): string => {
  if (error) {
    if (hasTruthyField(error, 'data') && hasTruthyField(error.data, 'message')) {
      return i18n.t('errors.badRequest', {
        msg: error.data.message as string
      }).toString();
    }
    if (hasTruthyField(error, 'response') && hasTruthyField(error.response, 'data') && hasTruthyField(error.response.data, 'message')) {
      return i18n.t('errors.badRequest', {
        msg: error.response.data.message as string
      }).toString();
    }
    if (error instanceof Error) {
      return i18n.t('errors.badRequest', {
        msg: error.message
      }).toString();
    }
    if (typeof error === 'string') {
      return error;
    }
  }
  return i18n.t('common.genericError').toString();
};
