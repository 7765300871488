import { Dialog, ButtonCallbacks } from '@/components/types/Dialog';

export const deleteUpdatePackageDialog: Dialog = {
  title: 'updatePackages.deleteUpdatePackage',
  dialogWidth: '70vh',
  processText: 'updatePackages.dialogDeleteIndicatorText',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.DELETE,
      text: 'common.delete'
    }]
};

export const importUpdatePackagesDialog: Dialog = {
  title: 'app.upload',
  dialogWidth: '90vh',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    }]
};
