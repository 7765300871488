<template>
  <tr class="filter-row">
    <td>
      <v-icon :color="colors.darkSecondary"> mdi-filter </v-icon>
    </td>
    <td v-for="header in enrichedHeaders" :key="header.title" class="py-2">
      <v-autocomplete
        v-if="header.filterable && mount"
        class="filter-autocomplete"
        :placeholder="$t('common.all')"
        :no-data-text="$t('common.noFilterData')"
        :items="returnFilterValues(header, items)"
        clear-icon="mdi-close"
        multiple
        hide-details
        clearable
        density="compact"
        :data-cy="('table-filter-' + header.value).split('.').join('-')"
        @update:model-value="collectFilterValues(header.value, $event)"
      />
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { colors } from '@/styles/colors';
import { DataTableHeader } from '../../types/DataTable';
import { returnFilterValues } from '@/util/dataTable';
export default defineComponent({
  name: 'FilterRow',
  props: {
    items: {
      type: Array<Record<string, unknown>>,
      required: true
    },
    enrichedHeaders: {
      type: Array<DataTableHeader>,
      required: true
    },
    // this 'mount' prop ensures, that the inputs fields are cleared after hiding the FilterRow
    mount: {
      type: Boolean,
      required: true
    }
  },
  emits: ['collectFilterValues'],
  data() {
    return {
      colors
    };
  },
  methods: {
    returnFilterValues,
    collectFilterValues(header: string, values: string[]) {
      this.$emit('collectFilterValues', header, values);
    }
  }
});
</script>
<style lang="scss" scoped>
@import '@/styles/colors.scss';
.filter-row {
  background-color: $table-header;
}
.filter-autocomplete:hover {
  background-color: $medium-shadow !important;
}
.filter-row:deep(.v-field--focused) {
  color: $secondary !important;
}
</style>
