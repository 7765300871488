/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { defineStore } from 'pinia';
import { Device, DeviceGroup } from '@/store/types/inventory/Device';
import ManagedDeviceApi from '@/services/inventory/ManagedDeviceApi';
import DeviceGroupApi from '@/services/inventory/DeviceGroupApi';
import { i18n } from '../../plugins/i18n';
import { OAM_CONNECTION_PROFILE } from '../../shared/constants';
import { downloadFile } from '../../util/downloadUtil';
import { showDownloadConfigFailureDialog } from '@/util/dialogUtil';
import ManagedDeviceEventLogApi from '@/services/inventory/ManagedDeviceEventLogApi';
import {
  showSnackbar,
  showErrorSnackbar,
  showApiErrorSnackbar,
  createErrorMessage
} from '@/util/snackBarUtil';
import { CustomFieldType } from '../types/identity/index';
import { EventLogType } from '../types/inventory/index';
import router from '@/router';

interface State {
  devices: Device[];
  groups: DeviceGroup[];
  eventLogs: EventLogType[];
  addAnotherRouterFlag: boolean;
}

export const deviceStore = defineStore('deviceStore', {
  state: (): State => ({
    devices: [],
    groups: [],
    // Only store the last requested event logs for a specific device
    // Since the event logs do update regularly, so we have to fetch them
    // every time we access them anyhow.
    eventLogs: [],
    addAnotherRouterFlag: false
  }),
  getters: {
    getDeviceById: (state: State) => (deviceId: string) =>
      state.devices.find((device: Device) => device.uuid === deviceId),
    getDeviceByName: (state: State) => (deviceName: string) =>
      state.devices.find((device: Device) => device.name === deviceName),
    getDevicesByGroupId: (state: State) => (groupId: string) =>
      state.devices.filter((device: Device) => device.groupUuid === groupId),
    getGroupNameForDevice: (state: State) => (device: Device) =>
      state.groups
        .filter((group: DeviceGroup) => group.uuid === device.groupUuid)
        .map((group: DeviceGroup) => group.name)
        .pop(),
    getGroupById: (state: State) => (id: string) =>
      state.groups.find((group: DeviceGroup) => group.uuid === id),
    getGroupByName: (state: State) => (groupName: string) =>
      state.groups.find((group: DeviceGroup) => group.name === groupName),
    getDeviceCountOfGroup: (state: State) => (groupId: string) =>
      state.devices.filter((device: Device) => device.groupUuid === groupId)
        .length
  },

  actions: {
    async fetchDevices(errorHandling = true) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.devices = (await ManagedDeviceApi.find()).data.members as Device[];
      } catch (err) {
        if (errorHandling) {
          showApiErrorSnackbar(
            createErrorMessage(i18n.tc('errors.fetchDevices')),
            err
          );
        }
        throw err;
      }
    },

    async fetchDevice(id: string) {
      try {
        const fetchedDevice = await ManagedDeviceApi.getById(id);
        this.updateDevices(fetchedDevice);
      } catch (error) {
        await router.push({
          name: 'error404',
          params: { navigateTo: '/devices' }
        });
      }
    },

    async fetchGroups(errorHandling = true) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.groups = (await DeviceGroupApi.find()).data
          .members as DeviceGroup[];
      } catch (err) {
        if (errorHandling) {
          showApiErrorSnackbar(
            createErrorMessage(i18n.tc('errors.fetchGroups')),
            err
          );
        }
        throw err;
      }
    },

    async fetchGroup(uuid: string, errorHandling = true) {
      try {
        const fetchedGroup = await DeviceGroupApi.getById(uuid);
        // We dont want the managedDevices in the group object in the store; inconsistent api!
        delete fetchedGroup.managedDevices;
        this.updateGroups(fetchedGroup);
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(
            createErrorMessage(i18n.t('errors.fetchGroups')),
            error
          );
        }
        throw error;
      }
    },
    async renameGroup(deviceGroup: DeviceGroup, newName: string, errorHandling = true) {
      try {
        const fetchedGroup = await DeviceGroupApi.rename(deviceGroup.uuid, newName);
        showSnackbar(
          i18n.t('deviceGroups.editGroupSuccessful', {
            msg: deviceGroup.name
          }).toString()
        );
        this.updateGroups(fetchedGroup);
      } catch (error) {
        if (errorHandling) {
          showApiErrorSnackbar(
            createErrorMessage(i18n.t('errors.editGroup')),
            error
          );
        }
        throw error;
      }
    },

    updateGroups(group: DeviceGroup) {
      const index = this.groups.findIndex((grp) => grp.uuid === group.uuid);
      if (index === -1) {
        this.groups.push(group);
      } else {
        this.groups.splice(index, 1, group);
      }
    },

    async downloadConfig(device: Device) {
      try {
        const response = await ManagedDeviceApi.getConnectionProfile(device);
        const fileName = `${OAM_CONNECTION_PROFILE}_${device.serialNumber}.tar`;
        downloadFile(response.data, fileName);
      } catch (error) {
        if (error.status === 409 || error.response.status) {
          showDownloadConfigFailureDialog();
        } else {
          showApiErrorSnackbar(createErrorMessage(error), error);
        }
      }
    },

    async deleteDevices(devicesToDelete: Device[]) {
      const promises: Promise<unknown>[] = [];
      try {
        devicesToDelete.forEach((device) => {
          promises.push(ManagedDeviceApi.delete(device.uuid));
        });
        return Promise.all(promises).then(async () => {
          await this.fetchDevices(false);
          showSnackbar(
            i18n
              .tc('devices.successfullDeletion', devicesToDelete.length, {
                name: devicesToDelete[0].name,
                count: devicesToDelete.length
              })
              .toString()
          );
        });
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async fetchEventLogs(deviceId: string) {
      try {
        await ManagedDeviceEventLogApi.find(deviceId).then((response) => {
          this.eventLogs = response.data.members;
        });
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async updateDevice(deviceToUpdate: Device, showSnackbarFlag = true) {
      try {
        await ManagedDeviceApi.updateDevice(deviceToUpdate);
        if (showSnackbarFlag) {
          showSnackbar(
            i18n
              .t('devices.successfullEdited', {
                name: deviceToUpdate.name
              })
              .toString()
          );
        }
      } catch (error) {
        showApiErrorSnackbar(
          i18n
            .t('common.serverError', {
              msg: createErrorMessage(error)
            })
            .toString(),
          error
        );
      }
    },

    async addDevice(newDevice: Device) {
      try {
        await ManagedDeviceApi.createDevice(newDevice);
        showSnackbar(
          i18n
            .t('devices.dialogDeviceAdded', {
              msg: newDevice.name,
              msg2: newDevice.serialNumber
            })
            .toString()
        );
      } catch (error) {
        if (error.status === 403) {
          showErrorSnackbar(
            i18n.t('devices.dialogDeviceLimitReached').toString()
          );
        } else {
          showApiErrorSnackbar(
            i18n
              .t('devices.dialogDeviceAddFailed', { msg: newDevice.name })
              .toString(),
            error
          );
        }
      }
    },

    async addDevicesFromFile(files: File[]) {
      for (const file of files) {
        try {
          const result = await ManagedDeviceApi.bulkImport(file);
          showSnackbar(
            i18n
              .t('devices.bulkImportSuccess', {
                msg: result.data.members.length,
                msg2: file.name
              })
              .toString()
          );
        } catch (error) {
          showApiErrorSnackbar(
            i18n
              .t('common.serverError', {
                msg: createErrorMessage(error)
              })
              .toString(),
            error
          );
        }
      }
      void this.fetchDevices(false);
    },

    async registerGroup(groupName: string) {
      try {
        const response = await DeviceGroupApi.create(groupName);
        const group = response.data as DeviceGroup;
        this.groups.push(group);
        this.groups = this.groups.sort((a, b) => a.name.localeCompare(b.name));
        showSnackbar(
          i18n.t('devices.dialogGroupAdded', { msg: groupName }).toString()
        );
      } catch (error) {
        showApiErrorSnackbar(
          i18n.t('devices.dialogGroupAddFailed', { msg: groupName }).toString(),
          error
        );
      }
    },

    async addCustomField(device: Device, customField: CustomFieldType) {
      if (customField.valueType === 'number') {
        customField.value = parseFloat((customField.value || '0').toString());
      }

      if (device.custom === null) {
        device.custom = {};
      }

      device.custom[customField.name] = customField.value;
      try {
        await this.updateDevice(device);
        await this.fetchDevices();
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async removeCustomField(device: Device, customField: CustomFieldType) {
      delete device.custom[customField.name];
      try {
        await this.updateDevice(device);
        await this.fetchDevices();
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    setAddAnotherRouterFlag(addAnotherRouterFlag: boolean) {
      this.addAnotherRouterFlag = addAnotherRouterFlag;
    },

    updateDevices(newDevice: Device) {
      const index = this.devices.findIndex(
        (device) => device.uuid === newDevice.uuid
      );
      if (index === -1) {
        this.devices.push(newDevice);
      } else {
        this.devices.splice(index, 1, newDevice);
      }
    }
  }
});
