<template>
  <v-list
    data-cy="table-actions-button-actions-list"
    class="action-list"
    density="compact"
  >
    <v-list-item
      v-for="action in config"
      :key="action.id"
      :data-cy="'table-actions-button-' + action.title.split('.').join('-')"
      @click="action.callback(item)"
      :prepend-icon="action.icon"
    >
      <v-row
        v-if="isAction(action)"
        no-gutters
        class="flex-nowrap"
        align="center"
      >
        {{ $tc(action.title, action.translateCount ?? 1) }}
      </v-row>
      <v-divider v-else-if="!isAction(action)"></v-divider>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ActionsButtonWithCallback } from '../../types/DataTable';
import { ButtonCallbacks } from '@/components/types/DataTable';

export default defineComponent({
  name: 'ActionButtonList',
  props: {
    config: {
      type: Array as () => ActionsButtonWithCallback[],
      required: true
    },
    item: {
      type: Object as () => Record<string, unknown>,
      required: false
    }
  },
  methods: {
    isAction(action: ActionsButtonWithCallback): boolean {
      return action.id !== ButtonCallbacks.NONE;
    }
  }
});
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.v-menu__content {
  box-shadow: 0 0.1 0 0.1 !important;
}

.action-list {
  background-color: $table-header;
}
</style>
