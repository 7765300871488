import { Dialog, ButtonCallbacks } from '@/components/types/Dialog';

export const addDeviceDialog: Dialog = {
  title: 'devices.addDevice',
  processText: 'devices.deviceAdd',
  dialogWidth: '85vh',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.ADD,
      text: 'common.add'
    }
  ]
};

export const addGroupDialog: Dialog = {
  title: 'app.addGroup',
  dialogWidth: '50vh',
  processText: 'devices.addingGroup',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.ADD,
      text: 'common.add'
    }
  ]
};

export const eventLogDialog: Dialog = {
  title: 'devices.eventLogForSelectedDevice',
  dialogWidth: '120vh',
  buttonConfig: []
};

export const deleteDeviceDialog: Dialog = {
  title: 'devices.deleteDevices',
  dialogWidth: '70vh',
  processText: 'devices.dialogDeleteIndicatorText',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.DELETE,
      text: 'common.delete'
    }
  ]
};

export const bulkImportDevicesDialog: Dialog = {
  title: 'devices.bulkImport',
  dialogWidth: '90vh',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.DOWNLOAD,
      text: 'devices.bulkImportDownloadExample',
      icon: 'mdi-download',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    }
  ]
};

export const nextStepsDeviceDialog: Dialog = {
  title: 'devices.nextSteps',
  dialogWidth: '70vh',
  actionFlagLabel: 'common.doNotShow',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.OK,
      text: 'common.ok',
      outlined: true
    }
  ]
};

export const bulkAddDeviceToGroupDialog: Dialog = {
  title: 'common.emptyMsgPlaceholder',
  dialogWidth: '45vh',
  processText: 'devices.addingToGroup',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.SAVE,
      text: 'common.save'
    }
  ]
};

export const deviceDetailsEditCustomFieldDialog: Dialog = {
  title: 'common.emptyMsgPlaceholder',
  dialogWidth: '70vh',
  processText: 'devices.addingToGroup',
  buttonConfig: [
    {
      callbackID: ButtonCallbacks.CANCEL,
      text: 'common.cancel',
      outlined: true
    },
    {
      callbackID: ButtonCallbacks.OK,
      text: 'common.ok'
    }
  ]
};
