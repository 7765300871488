<template>
  <v-btn
    id="create-button-main"
    width="160px"
    variant="flat"
    color="primary"
    class="text-none mr-4 px-4"
    data-cy="actions-menu-button"
    @click="showMenu = !showMenu"
    >{{ $tc('common.create', 1) }}
    <v-icon v-if="!showMenu" end>mdi-chevron-down</v-icon>
    <v-icon v-if="showMenu" end>mdi-chevron-up</v-icon>
    <v-menu activator="parent" transition="slide-y-transition">
      <v-list width="230px" class="pa-0">
        <v-list-subheader class="label subheader-list"
          >{{ $t('common.devices') }}
        </v-list-subheader>
        <v-list-item
          v-if="showQuickstartWizard"
          class="item"
          data-cy="configure-device-button"
          @click="openConfigureDeviceWizard"
        >
          <v-list-item-title class="text-body-2 font-weight-medium item-title"
            >{{ $t('app.configureDevice') }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item class="item" @click="showCreateDeviceDialog">
          <v-list-item-title class="text-body-2 font-weight-medium item-title"
            >{{ $t('app.addDevices') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="item" @click="showCreateGroupDialog">
          <v-list-item-title class="text-body-2 font-weight-medium item-title"
            >{{ $t('app.addGroup') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider
          v-if="
            getLicenseFeatures.updateJobsEnabled ||
            getLicenseFeatures.updatePacketManagementEnabled
          "
          class="mb-2"
        ></v-divider>
        <v-list-subheader
          v-if="
            getLicenseFeatures.updateJobsEnabled ||
            getLicenseFeatures.updatePacketManagementEnabled
          "
          class="label subheader-list"
          >{{ $tc('common.updates', 2) }}
        </v-list-subheader>
        <v-list-item
          v-if="getLicenseFeatures.updatePacketManagementEnabled"
          class="item"
          @click="showUploadUpdatePacket"
        >
          <v-list-item-title
            id="create-button-main-upload-packet"
            class="text-body-2 font-weight-medium item-title"
          >
            <v-icon size="small" start color="primary">mdi-cloud-upload</v-icon>
            {{ $t('app.upload') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="getLicenseFeatures.updateJobsEnabled"
          class="item"
          @click="openUpdateWizardDialog"
        >
          <v-list-item-title
            id="create-button-main-start-update-assistent"
            class="text-body-2 font-weight-medium item-title"
          >
            <v-icon size="small" start color="primary">mdi-autorenew</v-icon>
            {{ $t('app.assistant') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-btn>

  <AddDeviceDialog
    v-if="showAddDeviceDialog"
    :show-dialog="showAddDeviceDialog"
    @update:show-dialog="showAddDeviceDialog = false"
  />
  <AddGroupDialog
    v-if="showAddGroupDialog"
    :show-dialog="showAddGroupDialog"
    @update:show-dialog="showAddGroupDialog = false"
  />
  <ImportUpdatePackagesDialog
    v-if="showUploadDialog"
    :show-dialog="showUploadDialog"
    @update:show-dialog="showUploadDialog = false"
  />
</template>

<script lang="ts">
import { Setting } from '../../../store/types/identity/index';
import { defineComponent } from 'vue';
import AddDeviceDialog from '@/components/devices/dialogs/AddDeviceDialog.vue';
import AddGroupDialog from '@/components/devices/dialogs/AddGroupDialog.vue';
import ImportUpdatePackagesDialog from '@/components/updatePackages/dialogs/ImportUpdatePackagesDialog.vue';

export default defineComponent({
  name: 'QuickActionMenu',
  components: {
    AddDeviceDialog,
    AddGroupDialog,
    ImportUpdatePackagesDialog
  },
  props: {
    getLicenseFeatures: {
      type: Object as () => any,
      required: true
    },
    onpremises: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Array as () => Setting[],
      required: true
    }
  },

  data() {
    return {
      showMenu: false,
      showAddDeviceDialog: false,
      showAddGroupDialog: false,
      showUploadDialog: false
    };
  },
  computed: {
    showQuickstartWizard() {
      return (
        !this.onpremises &&
        !!this.settings.find(
          (setting: Setting) => setting.name === 'QUICKSTART_ENABLED'
        )?.value
      );
    }
  },
  methods: {
    openConfigureDeviceWizard() {
      void this.$router.push({ name: 'quickstart', params: { step: 1 } });
    },
    showCreateDeviceDialog() {
      this.showAddDeviceDialog = true;
    },
    showCreateGroupDialog() {
      this.showAddGroupDialog = true;
    },
    showUploadUpdatePacket(): void {
      this.showUploadDialog = true;
    },
    openUpdateWizardDialog(): void {
      void this.$router.push('/update-wizard');
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/styles/colors.scss';
.subheader-list {
  color: $top-header;
  height: 32px;
}
.item-title {
  color: $primary;
}
</style>
