/* eslint-disable max-lines */
import { defineStore } from 'pinia';
import { i18n } from '../../plugins/i18n';
import {
  showSnackbar,
  showApiErrorSnackbar,
  createErrorMessage
} from '@/util/snackBarUtil';
import {
  AuthenticationError,
  AuthStoreState,
  SignupDataType,
  UserInfo,
  UserLoginType
} from '../types/auth';
import { extractNavDrawerGroupsClosedMap } from '@/navigationDrawer/navigationDrawer';
import AuthService from '../../services/auth/AuthService';
import router from '@/router';
import { jwtDecode } from 'jwt-decode';
import OrganisationApi from '@/services/identity/OrganisationApi';
import UsersApi from '@/services/identity/UsersApi';
import {
  AccountMembershipType,
  Organisation,
  OrganisationRole,
  User,
  UserFieldTableHeader
} from '../types/identity';
import { Account } from '../types/identity/index';
import { License } from '@/models/licenses/licenseTypes';
import AccountsApi from '@/services/identity/AccountsApi';
import { TokenService } from '@/services/auth/TokenService';
import { deviceTableConfigurableColumns } from '@/models/inventory/deviceTableConfig';
import RefreshService from '@/services/RefreshService';

const RESHOW_CONFIRM_BAR_TIME = 1000 * 60 * 60 * 24; // 1 day
const NAVIGATION_GROUP_CLOSED_STATUSES = 'navigation_group_closed_statuses';
export const LOCAL_AUTH_STORE = 'auth';

const accountIsLicensed = (account: Account) => {
  if (account.activeLicenses?.length) {
    return true;
  }
  return false;
};

const accountHasTrialLicense = (account: Account) => {
  if (
    account.activeLicenses?.length === 1 &&
    account.activeLicenses[0].activationCode === '0000-0000-0000'
  ) {
    return true;
  }
  return false;
};

export const authenticationStore = defineStore('authenticationStore', {
  state: (): AuthStoreState => {
    const localAuthState = localStorage.getItem(LOCAL_AUTH_STORE);
    if (localAuthState) {
      return JSON.parse(localAuthState) as AuthStoreState;
    } else {
      return {
        authenticationError: '',
        signupError: '',
        signupSuccess: false,
        loggedIn: !!TokenService.getRefreshToken(),
        loggedInUser: {} as User,
        loggedInAccount: {},
        showTrialBar: false,
        showExpiredBar: false,
        showNotHomeAccountBar: false,
        confirmUserBarLastDismissed: null,
        hideReleaseBar: false,
        expiresNext: null,
        cookiesAccepted: true,
        signupToken: '',
        temporaryUserFields: {},
        navigationDrawerGroupClosed: localStorage.getItem(
          NAVIGATION_GROUP_CLOSED_STATUSES
        )
          ? (JSON.parse(
            localStorage.getItem(NAVIGATION_GROUP_CLOSED_STATUSES) ?? ''
          ) as Record<string, boolean>)
          : extractNavDrawerGroupsClosedMap(),
        licensedFeatures: {
          advancedUpdateEnabled: true,
          apiEnabled: true,
          auditLogEnabled: true,
          autoUpdateServerEnabled: true,
          certificateManagementEnabled: true,
          cliTemplatesEnabled: true,
          customFieldsEnabled: true,
          updateJobsEnabled: true,
          updatePacketManagementEnabled: true,
          userManagementEnabled: true
        },
        ignoreLicenseBars: false
      };
    }
  },

  getters: {
    isAdminAccount: (state: AuthStoreState) =>
      !!state.loggedInUser?.organisation?.roles?.find(
        (role: string) =>
          role === OrganisationRole.SUPPLIER ||
          role === OrganisationRole.BROKER ||
          role === OrganisationRole.RESELLER ||
          role === OrganisationRole.PLATFORM_OPERATOR
      ),
    allowChangeAccount: (state: AuthStoreState) => {
      const roles = state.loggedInUser?.organisation?.roles?.find(
        (role: string) =>
          role === OrganisationRole.SUPPLIER ||
          role === OrganisationRole.BROKER ||
          role === OrganisationRole.RESELLER ||
          role === OrganisationRole.PLATFORM_OPERATOR
      );
      if (!roles) {
        const isCustomer = state.loggedInUser?.organisation?.roles?.includes(
          OrganisationRole.CUSTOMER
        );
        if (
          !!isCustomer &&
          (state.loggedInUser?.accountMemberships?.length || 0) > 1
        ) {
          return true;
        }
      }
      return roles;
    },
    isInternal: (state: AuthStoreState) =>
      state.loggedInAccount?.internal ?? false,
    getCurrentTableSettings: (state: AuthStoreState) =>
      state.loggedInAccount?.userFields,
    numberOfAppBars: (state: AuthStoreState) => {
      const bars = [
        state.showTrialBar,
        state.showExpiredBar,
        state.showNotHomeAccountBar,
        !!Object.keys(state.loggedInUser || {}).length &&
          !state.loggedInUser?.confirmed &&
          (state.confirmUserBarLastDismissed || 0) + RESHOW_CONFIRM_BAR_TIME <=
            Date.now()
      ];
      return bars.filter(Boolean).length;
    },
    showConfirmUserBar: (state: AuthStoreState) =>
      !!Object.keys(state.loggedInUser || {}).length &&
      !state.loggedInUser?.confirmed &&
      (state.confirmUserBarLastDismissed || 0) + RESHOW_CONFIRM_BAR_TIME <=
        Date.now()
  },

  actions: {
    async login(userCredentials: UserLoginType) {
      this.authenticationError = '';
      try {
        const accessToken = (await AuthService.login(
          userCredentials
        )) as string;
        await this.saveLoginInformation(accessToken);
        await router.push(
          (router as any)?.history?.current?.query?.redirect || '/'
        );
      } catch (e) {
        if (e instanceof AuthenticationError) {
          this.authenticationError = e.message;
        }
        throw e;
      }
    },

    async setInitialAccountSettings(onPremises = false) {
      this.setUserFields(onPremises);
      await AccountsApi.update(this.loggedInAccount as Account);
      RefreshService.getNewAccessToken();
      await this.saveLoginInformation(TokenService.getAccessToken() ?? '');
      showSnackbar(
        i18n.t('administration.editAccountSuccess', {
          msg: this.loggedInAccount.name
        }).toString()
      );
    },

    async setLoggedInUser(accessToken: string) {
      const parsedToken = jwtDecode<UserInfo>(accessToken);
      const userResponse = await UsersApi.getByUsername(parsedToken.username);
      const organisationResponse = await OrganisationApi.getById(
        userResponse.data.organisationUuid
      );
      const user = userResponse.data as User;
      user.organisation = organisationResponse.data;
      this.updateLoggedInUser(user);
    },

    updateLoggedInUser(user: User) {
      this.loggedInUser = user;

      if (user.preferredLanguage === 'de_DE') {
        localStorage.setItem('currentLang', 'de-DE');
        i18n.locale = 'de-DE';
      }
      if (user.preferredLanguage === 'en_US') {
        localStorage.setItem('currentLang', 'en-GB');
        i18n.locale = 'en-GB';
      }

      // check if account is already confirmed
      if (!user.confirmed) {
        this.confirmUserBarLastDismissed = 0;
      }

      if (user.release) {
        this.hideReleaseBar = false;
      }
    },

    async setLoggedInAccount(accessToken: string) {
      const parsedToken = jwtDecode<UserInfo>(accessToken);
      const accountResponse = await AccountsApi.getByName(parsedToken.account);
      const account = accountResponse.data as Account;
      this.updateLoggedInAccount(account);
      if (!this.getCurrentTableSettings) {
        this.setUserFields();
      }
    },

    updateLoggedInAccount(account: Account) {
      this.loggedInAccount = account;
      // reset default values for bars
      this.showTrialBar = false;
      this.showExpiredBar = false;
      this.expiresNext = null;

      if (account.internal) {
        // Keep defaults (false)
      } else if (!accountIsLicensed(account)) {
        if (account.namespace === 'system') {
          if (!this.ignoreLicenseBars) {
            this.showExpiredBar = false;
            this.showTrialBar = false;
          }
        } else if (!this.ignoreLicenseBars) {
          this.showExpiredBar = true;
          this.showTrialBar = false;
        }
      } else if (accountHasTrialLicense(account)) {
        if (!this.ignoreLicenseBars) {
          this.showExpiredBar = false;
          this.showTrialBar = true;
        }
      } else {
        // check which license expires soon (<45 Days)
        const soonExpiringLicenses: License[] = [];
        account.activeLicenses?.forEach((license) => {
          const expires = new Date(license.expiresAt);
          const now = new Date();
          const leftDays = Math.floor(
            (expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
          );
          if (leftDays < 46) {
            soonExpiringLicenses.push(license);
          }
        });

        // get the license which expires next
        soonExpiringLicenses.sort(
          (a, b) =>
            new Date(a.expiresAt).getTime() - new Date(b.expiresAt).getTime()
        );

        if (!this.ignoreLicenseBars) {
          this.showExpiredBar = false;
          this.showTrialBar = false;
        }
        this.expiresNext = soonExpiringLicenses[0];
        this.ignoreLicenseBars = false;
      }

      // check if logged into home account
      const accountMembership = this.loggedInUser?.accountMemberships?.find(
        (membership: AccountMembershipType) =>
          membership.accountUuid === this.loggedInAccount?.uuid
      );
      this.showNotHomeAccountBar = !accountMembership?.isHomeAccount;
    },

    setUserFields(onPremises = false) {
      let defaultUserFields = {};
      if (onPremises) {
        defaultUserFields = {
          devices: deviceTableConfigurableColumns.filter(
            (header) =>
              header.default === true && header.excludedInOnPrem !== onPremises
          )
        };
      } else {
        defaultUserFields = {
          devices: deviceTableConfigurableColumns.filter(
            (header) =>
              header.default === true &&
              (header.excludedInCloud ?? false) === onPremises
          )
        };
      }
      this.loggedInAccount.userFields = defaultUserFields;
    },

    updateNavDrawerGroupStates(navDrawerGroupStates: Record<string, boolean>) {
      this.navigationDrawerGroupClosed = {
        ...this.navigationDrawerGroupClosed,
        ...navDrawerGroupStates
      };
      localStorage.setItem(
        NAVIGATION_GROUP_CLOSED_STATUSES,
        JSON.stringify(this.navigationDrawerGroupClosed)
      );
    },

    resetNavDrawerGroupStates() {
      localStorage.removeItem(NAVIGATION_GROUP_CLOSED_STATUSES);
      this.navigationDrawerGroupClosed = extractNavDrawerGroupsClosedMap();
    },
    updateUserFieldsForDevicesTable(
      accountSetUserFieldsType: UserFieldTableHeader[]
    ) {
      this.temporaryUserFields = {
        ...this.temporaryUserFields,
        devices: accountSetUserFieldsType
      };
    },

    async updateCurrentAccount() {
      try {
        if (Object.keys(this.temporaryUserFields).length) {
          this.loggedInAccount.userFields = this.temporaryUserFields;
        }
        const updatedUserAccount = await AccountsApi.update(
          this.loggedInAccount as Account
        );

        this.ignoreLicenseBars =
          (updatedUserAccount.data.activeLicenses === null ||
            updatedUserAccount.data.activeLicenses === undefined) &&
          !!this.loggedInAccount.activeLicenses;
        // restore licenses | ignore empty response
        // TODO: pass param if licenses should be updated or not (**IF** this is ever required....)
        // probably these old stores should be re-written anyway so a "hack" should be fine...
        updatedUserAccount.data.activeLicenses =
          this.loggedInAccount.activeLicenses;
        this.updateLoggedInAccount(updatedUserAccount.data as Account);
        RefreshService.getNewAccessToken();
        await this.saveLoginInformation(TokenService.getAccessToken() ?? '');
        this.temporaryUserFields = {};
        showSnackbar(
          i18n
            .t('administration.editAccountSuccess', {
              msg: this.loggedInAccount.name
            })
            .toString()
        );
      } catch (error) {
        showApiErrorSnackbar(createErrorMessage(error), error);
      }
    },

    async signup(signupData: SignupDataType) {
      try {
        const accessToken = await AuthService.signup(signupData);
        this.signupSuccess = true;
        this.signupError = '';
        this.signupToken = accessToken;
      } catch (error) {
        this.signupError = createErrorMessage(error);
      }
    },

    async loginWithSignupToken() {
      this.authenticationError = '';
      this.signupError = '';
      await this.saveLoginInformation(this.signupToken);
      this.signupToken = '';
      router.go(0);
    },

    async logout() {
      this.resetStore();
      await AuthService.logout();
    },

    resetStore() {
      this.resetNavDrawerGroupStates();
      this.loggedIn = false;
      this.loggedInUser = <User>{};
      this.loggedInAccount = <Account>{};
      this.showTrialBar = false;
      this.showExpiredBar = false;
      this.expiresNext = null;
      this.showNotHomeAccountBar = false;
      localStorage.removeItem(LOCAL_AUTH_STORE);
    },

    async refreshLoggedInUser() {
      const response = await UsersApi.getByUsername(
        this.loggedInUser.username as string
      );
      const organisationResponse = await OrganisationApi.getById(
        response.data.organisationUuid
      );
      response.data.organisation = organisationResponse.data;
      this.updateLoggedInUser(response.data);
    },

    async switchAccount(accountName: string) {
      this.authenticationError = '';
      try {
        const accessToken = await AuthService.switch(accountName);
        await this.saveLoginInformation(accessToken);
        router.go(0);
        localStorage.removeItem(NAVIGATION_GROUP_CLOSED_STATUSES);
        return true;
      } catch (e) {
        if (e instanceof AuthenticationError) {
          this.authenticationError = e.message;
        }
        return false;
      }
    },
    setLicensedFeatures(accessToken: string) {
      this.licensedFeatures =
        jwtDecode<UserInfo>(accessToken).licenseExtensions;
    },
    hideTrialBar() {
      this.showTrialBar = false;
    },
    hideConfirmUserBar() {
      this.confirmUserBarLastDismissed = Date.now();
    },
    closeReleaseBar() {
      this.hideReleaseBar = true;
    },
    resetExpiresNext() {
      this.expiresNext = null;
    },
    async requestConfirmationLink() {
      try {
        await AuthService.requestConfirmationLink(
          this.loggedInUser?.uuid || ''
        );
        showSnackbar(i18n.t('app.successRequestConfirmation').toString());
      } catch (error) {
        showApiErrorSnackbar(
          i18n
            .t('app.errorRequestConfirmation', {
              msg: createErrorMessage(error)
            })
            .toString(),
          error
        );
      }
    },
    updateLoggedInUserOrganisation(organisation: Organisation) {
      const loggedInUserOrganisation = this.loggedInUser?.organisation;
      if (
        this.loggedInUser &&
        loggedInUserOrganisation &&
        loggedInUserOrganisation.uuid === organisation.uuid
      ) {
        this.loggedInUser.organisation = organisation;
      }
    },
    setCookiesAccepted(accepted: boolean) {
      this.cookiesAccepted = accepted;
    },
    async refreshLoggedInAccount(accountName: string) {
      const accountResponse = await AccountsApi.getByName(accountName);
      const account = accountResponse.data as Account;
      const organisationResponse = await OrganisationApi.getById(
        account.organisationUuid
      );
      account.organisation = organisationResponse.data;
      this.updateLoggedInAccount(account);
    },
    async saveLoginInformation(accessToken: string) {
      this.setLicensedFeatures(accessToken);
      await this.setLoggedInUser(accessToken);
      await this.setLoggedInAccount(accessToken);
      this.loggedIn = true;
      localStorage.setItem(LOCAL_AUTH_STORE, JSON.stringify(this.$state));
    }
  }
});
