/* eslint-disable prefer-arrow/prefer-arrow-functions, max-lines */
import { Store } from 'vuex';
import EditTemplateDialog from '@/components/editDeployment/editSubject/EditTemplateDialog.vue';
import DownloadConfigFailureDialog from '@/components/devices/DownloadConfigFailureDialog.vue';
import { DeploymentType } from '@/store/types';

export function showDownloadConfigFailureDialog(): void {
  dynamicallyMountComponent_RemoveThisCrapFFS(DownloadConfigFailureDialog);
}

export function showEditTemplateDialog(
  deployment: DeploymentType,
  title: string,
  individualId: string,
  store: Store<any>
): void {
  dynamicallyMountComponent_RemoveThisCrapFFS(EditTemplateDialog, {
    store,
    propsData: {
      deployment,
      title,
      individualId
    }
  });
}

export function showFeedbackDialog(): void {
  // TODO: delete this function , causes strange behaviour when deleted
  // function call in NavigationDrawer is useless, but needed ?!
}
