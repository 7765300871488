/* eslint-disable prefer-arrow/prefer-arrow-functions */
import Api from '@/services/ApiService';
import { Device } from '@/store/types';
import { AxiosRequestConfig } from 'axios';
import { QuickStartParams } from '../../store/types/inventory/index';
export default {
  find() {
    return Api.get('inventory/managed-devices');
  },
  async getById(id: string): Promise<Device> {
    const response = await Api.get(`inventory/managed-devices/${id}`);
    return response.data;
  },
  createDevice(device: Device) {
    // Set default values to required fields if empty
    if (!device.connectionSessionTimeout) {
      device.connectionSessionTimeout = 120;
    }
    if (!device.connectionPingInterval) {
      device.connectionPingInterval = 104;
    }
    if (!device.connectionPongTimeout) {
      device.connectionPongTimeout = 16;
    }

    return Api.post('inventory/managed-devices', device);
  },
  updateDevice(device: Device) {
    return Api.put(`inventory/managed-devices/${device.uuid}`, device);
  },
  getConnectionProfile(device: Device) {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.get(
      `inventory/managed-devices/${device.uuid}/connection-profile?format=update-packet`,
      config
    );
  },
  delete(id: string | unknown) {
    return Api.delete(`inventory/managed-devices/${id}`);
  },
  bulkImport(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return Api.post('inventory/managed-devices/bulk-import', formData);
  },
  createConfigurationFile: (quickStartParams: QuickStartParams) => {
    const config: AxiosRequestConfig = {
      responseType: 'blob'
    };
    return Api.post(
      'ui/inventory/managed-devices/quickstart',
      quickStartParams,
      config
    ) as unknown;
  }
};
