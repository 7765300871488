/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { i18n } from '@/plugins/i18n';
import { KeysConfiguration } from '@/store/types';
import { CliTemplateType } from '../store/types/inventory/index';
import { constants } from '@/models/autoupdate/constants';
export interface ConfigChangeTemplateHeaderType {
  text: string;
  value: string;
  align?: string;
  width?: string;
}

export interface KeyType {
  valueType?: string;
  translation?: string;
  internalProperties?: InternalProperty;
  divider?: boolean;
  header?: string;
  props?: Record<string, unknown>;
}

export interface InternalProperty {
  identifier:
  | 'pki:certificate-authority'
  | 'pki:certificate-key'
  | 'pki:certificate'
  | 'inventory:managed-device';
  reference?:
  | 'devicecontrol-certificate'
  | 'devicecontrol-certificate-key'
  | 'devicecontrol-certificate-authority'
  | 'devicecontrol-uuid'
  | 'autoupdate-certificate'
  | 'autoupdate-certificate-key'
  | 'autoupdate-certificate-authority';
}

export interface KeysConfigurationColumnType {
  label: string;
  field: string;
  type: string;
  internalProperties?: Record<
  string | number | symbol,
  Record<string | number | symbol, unknown> | string
  >;
}

export default {
  keyTypes(): KeyType[] {
    return [
      {
        valueType: 'string',
        translation: i18n.tc('templates.keyTypeString')
      },
      { divider: true },
      { header: i18n.tc('templates.keyTypeInternal') },
      {
        valueType: 'internal',
        translation: `CA-${i18n.tc('templates.keyTypeInternal')}`,
        internalProperties: {
          identifier: 'pki:certificate-authority'
        }
      },
      {
        valueType: 'internal',
        translation: i18n.tc('templates.keyTypeInternal'),
        internalProperties: {
          identifier: 'pki:certificate'
        }
      },
      {
        valueType: 'internal',
        translation: i18n.tc('templates.keyTypeInternalCertificateKey'),
        internalProperties: {
          identifier: 'pki:certificate-key'
        }
      },
      {
        valueType: 'internal',
        translation: `${i18n.tc(
          'templates.keyTypeInternalRouterAuth'
        )} ${i18n.tc('templates.keyTypeInternalClientCertificate')}`,
        internalProperties: {
          identifier: 'inventory:managed-device',
          reference: 'autoupdate-certificate'
        }
      },
      {
        valueType: 'internal',
        translation: `${i18n.tc(
          'templates.keyTypeInternalRouterAuth'
        )} ${i18n.tc('templates.keyTypeInternalPrivateKey')}`,
        internalProperties: {
          identifier: 'inventory:managed-device',
          reference: 'autoupdate-certificate-key'
        }
      },
      {
        valueType: 'internal',
        translation: `${i18n.tc(
          'templates.keyTypeInternalRouterAuth'
        )} CA-${i18n.tc('templates.keyTypeInternal')}`,
        internalProperties: {
          identifier: 'inventory:managed-device',
          reference: 'autoupdate-certificate-authority'
        }
      },
      {
        valueType: 'internal',
        translation: `${i18n.tc(
          'templates.managedDeviceUUID'
        )}`,
        internalProperties: {
          identifier: 'inventory:managed-device',
          reference: 'devicecontrol-uuid'
        }
      }
    ];
  },
  changeTemplateTextInPosition(templateTextarea, name) {
    const textarea = templateTextarea.$refs.input;
    const sentence = textarea.value;
    let pos = textarea.selectionStart;
    if (pos === undefined) {
      pos = 0;
    }

    const before = sentence.substr(0, pos);
    const after = sentence.substr(pos, sentence.length);

    // eslint-disable-next-line
    const value = before + name + after;
    textarea.value = value;
    // eslint-disable-next-line
    textarea.selectionStart = pos + name.length;
    return new Promise((resolve) => resolve(value));
  },
  tableHeader(): ConfigChangeTemplateHeaderType[] {
    return [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        width: '50%'
      },
      { text: 'Typ', value: 'type', width: '50%' },
      { text: 'Actions', value: 'actions', align: 'end' }
    ];
  },
  configurationsByValueType(
    valueType: string,
    event,
    keyConfiguration: KeysConfiguration
  ) {
    if (event.valueType === 'internal') {
      keyConfiguration.internalProperties = {
        identifier: event.identifier
      };
      if (event.reference) {
        keyConfiguration.internalProperties.reference = event.reference;
      }
    } else {
      delete keyConfiguration.internalProperties;
    }

    return keyConfiguration;
  },
  getNextKeyConfigurationName(
    keysConfiguration: KeysConfiguration[]
  ): string {
    const foundKeys: number[] = [];

    keysConfiguration.forEach((keyConfiguration) => {
      if (keyConfiguration.key.includes('param')) {
        const keyCounter = keyConfiguration.key.match(/(\d+)/);
        if (keyCounter && !isNaN(Number(keyCounter[0]))) {
          foundKeys.push(Number(keyCounter[0]));
        }
      }
    });
    const max = foundKeys.length ? Math.max(...foundKeys) : 0;
    return `param${max + 1}`;
  },
  isDuplicatedKeyName(
    keyName,
    keysConfiguration: KeysConfiguration[]
  ): boolean {
    let foundKeys = 0;
    for (const keyConfiguration of keysConfiguration) {
      if (keyConfiguration.key === keyName) {
        foundKeys++;
        if (foundKeys > 1) break;
      }
    }
    return foundKeys > 1;
  },
  storeHelper(cliTemplate) {
    const allDeletedItems = [] as any[];
    cliTemplate.keysConfiguration.forEach((keyConfiguration) => {
      if (keyConfiguration.isDeleted) {
        allDeletedItems.push(keyConfiguration);
      }
    });
    allDeletedItems.sort((a, b) => b.deletedAt - a.deletedAt);

    if (allDeletedItems.length >= 1) {
      cliTemplate.keysConfiguration = cliTemplate.keysConfiguration.map(
        (keyConfiguration) => {
          if (keyConfiguration.isDeleted) {
            const newestItem = allDeletedItems[0];
            if (keyConfiguration.deletedAt === newestItem.deletedAt) {
              delete keyConfiguration.deletedAt;
              delete keyConfiguration.isDeleted;
            }
          }
          return keyConfiguration;
        }
      );
    }
  },
  filterKeysConfigurationColumns(
    columns: KeysConfigurationColumnType[]
  ): KeysConfigurationColumnType[] {
    return columns.filter(
      (keyConfiguration) =>
        keyConfiguration.internalProperties?.reference !==
          'autoupdate-certificate' &&
        keyConfiguration.internalProperties?.reference !==
          'autoupdate-certificate-key' &&
        keyConfiguration.internalProperties?.reference !==
          'autoupdate-certificate-authority'
    );
  },
  filterCustomFieldColumns(
    columns: KeysConfigurationColumnType[]
  ): KeysConfigurationColumnType[] {
    return columns.filter(
      (keyConfiguration) =>
        !keyConfiguration.internalProperties?.reference
          ?.toString()
          ?.match(/^custom:/)
    );
  }
};

export const getCliTemplateStatusName = (template: CliTemplateType) => {
  switch (template.status) {
    case constants.CONFIG_CHANGE_TEMPLATE_STATUS_AVAILABLE:
      return i18n.t('templates.statusAvailable');
    case constants.CONFIG_CHANGE_TEMPLATE_STATUS_LINKED:
      return i18n.t('templates.statusLinked');
    default:
      return i18n.t('templates.statusUnknown');
  }
};
