<template>
  <div>
    <div v-for="item in navigationItems" :key="item.title">
      <v-list-item
        v-if="
          !!item.path &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        :title="$t(item.title)"
        :prepend-icon="item.icon"
        :color="'#00a5dc'"
        :value="item"
        @click="$router.push(item.path)"
      ></v-list-item>
      <v-list-group
        v-if="
          !item.path &&
          navigationItemVisible(item.featureFlags, licensedFeatures)
        "
        color="primary"
        class="submenu"
      >
        <template #activator="{ props }">
          <v-list-item
            :title="$t(item.title)"
            :prepend-icon="item.icon"
            v-bind="props"
            :value="item"
            class="subsubmenu"
          >
          </v-list-item>
        </template>
        <NavigationGroup :navigation-items="item.children"></NavigationGroup>
      </v-list-group>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import {
  NavigationItem,
  navigationItemVisible
} from '../../../navigationDrawer/navigationDrawerConfig';
import { mapState } from 'pinia';
import { authenticationStore } from '@/store/pinia/AuthenticationStore';

const NavigationGroup = defineComponent({
  name: 'NavigationGroup',
  props: {
    navigationItems: {
      type: Array<NavigationItem>,
      required: true
    }
  },

  computed: {
    ...mapState(authenticationStore, ['licensedFeatures']),
    navigationItemVisible(): typeof navigationItemVisible {
      return navigationItemVisible;
    }
  }
});
export default NavigationGroup;
</script>

<style lang="scss">
@import '@/styles/colors.scss';
.list-item {
  background-color: $secondary-background !important;
}
.list-group {
  background-color: white !important;
}
.collapsed-active {
  background-color: $primary-background !important;
}
.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: black !important;
}
.v-list .v-list-item--active {
  color: black !important;
}
.v-list-group .list-item .v-list-group--no-action {
  color: black !important;
}
.theme--light.v-icon {
  color: black;
}
</style>
