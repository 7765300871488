<template>
  <ExtendedDialog
    :show-dialog="show"
    :process-action="processing"
    :config="config"
    @execute-action="execute($event)"
  >
    <v-container class="dialog-content">
      <FileUploader
        class="pb-4"
        :file-types="fileTypes"
        :processing="processing"
        :multiple="$attrs['multiple']"
        @add-files="addFiles"
      />
      <v-alert
        v-if="$attrs['direct-upload'] && !processing"
        type="info"
        variant="tonal"
        color="primary"
        :text="$t(infoMessage)"
      />
    </v-container>
  </ExtendedDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ExtendedDialog from '@/components/common/dialog/ExtendedDialog.vue';
import { Dialog } from '@/components/types/Dialog';
import FileUploader from '@/components/common/FileUploader.vue';

export default defineComponent({
  name: 'UploadDialog',
  components: { ExtendedDialog, FileUploader },
  emits: ['update:showDialog', 'addFiles', 'executeAction'],
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    processing: {
      type: Boolean,
      required: true
    },
    config: {
      type: Object as () => Dialog,
      required: true
    },
    fileTypes: {
      type: String,
      default: '*/*'
    },
    infoMessage: {
      type: String,
      default: 'common.defaultAutomaticUpload'
    }
  },
  computed: {
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    }
  },
  methods: {
    execute(callbackID) {
      this.$emit('executeAction', callbackID);
    },
    addFiles(files) {
      this.$emit('addFiles', files);
    }
  }
});
</script>
