import {
  ActionsButton,
  ActionTypes,
  ButtonCallbacks,
  DataTableHeader,
  TableStatusChip
} from '@/components/types/DataTable';
import { DataTable, IconButton } from '../../components/types/DataTable';
import {
  MANAGED_DEVICE_ADMIN_STATUS_MANAGED,
  MANAGED_DEVICE_CONNECTIVITY_STATUS_ONLINE,
  MANAGED_DEVICE_CONNECTIVITY_STATUS_OFFLINE,
  MANAGED_DEVICE_ADMIN_STATUS_COMMISSIONING,
  MANAGED_DEVICE_ADMIN_STATUS_SERVICE,
  MANAGED_DEVICE_ADMIN_STATUS_ERROR,
  SMART_UPDATE_PROGRESS_SUCCESS
} from '@/models/inventory/constants';
import { Device } from '@/store/types';
import { SmartUpdateStatus } from '../../store/types/inventory/Device';
import { UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT } from '../rollouts/constants';
import { colors } from '@/styles/colors';
import { dateFormatter } from '@/util/dateFormatter';

export const deviceTablebuttons: IconButton[] = [
  {
    id: ButtonCallbacks.ADD,
    icon: 'mdi-plus',
    title: 'common.add'
  },
  {
    id: ButtonCallbacks.REFRESH,
    icon: 'mdi-refresh',
    title: 'common.refresh'
  }
];

export const deviceActionsButtonConfig: ActionsButton[] = [
  {
    id: ButtonCallbacks.WIZARD,
    icon: 'mdi-update',
    title: 'devices.actionButtonActions.runUpdate',
    types: [ActionTypes.SINGLE, ActionTypes.MULTIPLE]
  },
  {
    id: ButtonCallbacks.NONE,
    title: 'common.empty',
    types: [ActionTypes.SINGLE, ActionTypes.MULTIPLE]
  },
  {
    id: ButtonCallbacks.ADD,
    icon: 'mdi-plus',
    title: 'common.add',
    types: [ActionTypes.NONE]
  },
  {
    id: ButtonCallbacks.EDIT,
    icon: 'mdi-pencil',
    title: 'common.edit',
    types: [ActionTypes.SINGLE]
  },
  {
    id: ButtonCallbacks.DOWNLOAD,
    icon: 'mdi-download',
    title: 'devices.downloadStartConfig',
    types: [ActionTypes.SINGLE]
  },
  {
    id: ButtonCallbacks.TOGGLE,
    icon: 'mdi-autorenew',
    title: 'devices.actionButtonActions.automaticFirmwareAutoUpdate',
    types: [ActionTypes.SINGLE, ActionTypes.MULTIPLE]
  },
  {
    id: ButtonCallbacks.REMOVE,
    icon: 'mdi-delete',
    title: 'common.delete',
    types: [ActionTypes.SINGLE, ActionTypes.MULTIPLE]
  },
  {
    id: ButtonCallbacks.LOGS,
    icon: 'mdi-note-text',
    title: 'devices.showEventLog',
    types: [ActionTypes.SINGLE]
  },
  {
    id: ButtonCallbacks.UPLOAD,
    icon: 'mdi-cloud-upload',
    title: 'devices.bulkImport',
    types: [ActionTypes.NONE]
  },
  {
    id: ButtonCallbacks.NONE,
    title: 'divider',
    types: [ActionTypes.NONE]
  },
  {
    id: ButtonCallbacks.EDIT_BULK,
    icon: 'mdi-tag-plus',
    title: 'devices.actionButtonActions.addToGroup',
    types: [ActionTypes.MULTIPLE, ActionTypes.SINGLE]
  },
  {
    id: ButtonCallbacks.EDIT_TABLE,
    icon: 'mdi-table-edit',
    title: 'devices.actionButtonActions.tableSettings',
    types: [ActionTypes.NONE]
  }
];

export const deviceTableConfig: DataTable = {
  tableId: 'deviceTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'common.devices' },
    iconButtonConfig: []
  },
  noDataConfig: {
    actionButtonText: 'devices.noDataButtonText',
    alertMessage: 'devices.noDataAlertText',
    icon: 'mdi-router-wireless',
    callback: undefined
  }
};

export const deviceTableConfigUpdateJobWizard: DataTable = {
  tableId: 'deviceTableUpdateJobWizard',
  toolbarConfig: {
    titleCounterConfig: { title: 'common.devices' },
    iconButtonConfig: [],
    hasFilterFunctionality: false
  },
  noDataConfig: {
    actionButtonText: 'devices.noDataButtonText',
    alertMessage: 'devices.noDataAlertText',
    icon: 'mdi-router-wireless',
    callback: undefined
  }
};

export const updateJobDeviceTableConfig: DataTable = {
  tableId: 'updateJobDeviceTable',
  toolbarConfig: {
    titleCounterConfig: { title: 'common.devices' },
    iconButtonConfig: []
  }
};

export const deviceStatusConfig: TableStatusChip[] = [
  {
    statusString: MANAGED_DEVICE_ADMIN_STATUS_MANAGED,
    translationKey: 'common.statusChip.managed',
    color: colors.primary
  },
  {
    statusString: MANAGED_DEVICE_ADMIN_STATUS_COMMISSIONING,
    translationKey: 'common.statusChip.commissioning',
    color: colors.notConfigured
  },
  {
    statusString: MANAGED_DEVICE_ADMIN_STATUS_SERVICE,
    translationKey: 'common.statusChip.service',
    color: colors.warning
  },
  {
    statusString: MANAGED_DEVICE_CONNECTIVITY_STATUS_ONLINE,
    translationKey: 'common.statusChip.online',
    color: colors.success
  },
  {
    statusString: MANAGED_DEVICE_CONNECTIVITY_STATUS_OFFLINE,
    translationKey: 'common.statusChip.offline',
    color: colors.mediumShadow
  },
  {
    statusString: MANAGED_DEVICE_ADMIN_STATUS_ERROR,
    translationKey: 'common.statusChip.error',
    color: colors.danger
  }
];

export const smartUpdateStatusConfig: TableStatusChip[] = [
  {
    statusString: SMART_UPDATE_PROGRESS_SUCCESS,
    translationKey: 'common.statusChip.success',
    color: colors.success
  },
  {
    statusString: SmartUpdateStatus.FAILED,
    translationKey: 'common.statusChip.failed',
    color: colors.danger
  },
  {
    statusString: SmartUpdateStatus.PENDING,
    translationKey: 'common.statusChip.pending',
    color: colors.secondary
  },
  {
    statusString: SmartUpdateStatus.LATEST,
    translationKey: 'common.statusChip.latest',
    color: colors.success
  },
  {
    statusString: SmartUpdateStatus.SCHEDULED,
    translationKey: 'common.statusChip.scheduled',
    color: colors.darkSecondary
  },
  // Timeout is an UpdateJobState; needed to display error tatus of smart update in table
  {
    statusString: UPDATE_JOB_DEVICE_PROGRESS_TIMEOUT,
    translationKey: 'common.statusChip.failed',
    color: colors.danger
  },
  {
    statusString: SmartUpdateStatus.UNKNOWN,

    translationKey: 'common.statusChip.unknown',
    color: colors.mediumShadow
  }
];

export const deviceTableConfigurableColumns: DataTableHeader<Device>[] = [
  {
    title: 'devices.name',
    value: 'name',
    default: true
  },
  {
    title: 'devices.firmware',
    value: 'firmwareVersion',
    default: true
  },
  {
    title: 'devices.group',
    value: 'groupName',
    default: true
  },
  {
    title: 'devices.location',
    value: 'location',
    default: true
  },
  {
    title: 'devices.firmwareAutoUpdate',
    value: 'firmwareAutoUpdate',
    default: true,
    excludedInOnPrem: true
  },
  {
    title: 'devices.clientIP',
    value: 'url',
    default: true,
    excludedInCloud: true
  },
  {
    title: 'devices.connectionStatus',
    value: 'connectionStatus',
    default: true
  },
  {
    title: 'devices.createdAt',
    value: 'createdAt',
    valueTransformer: (_, item: Device) =>
      dateFormatter.dateWithoutSeconds(item.createdAt)
  },
  {
    title: 'devices.updatedAt',
    value: 'updatedAt',
    valueTransformer: (_, item: Device) =>
      dateFormatter.dateWithoutSeconds(item.updatedAt)
  },
  {
    title: 'devices.serialNumber',
    value: 'serialNumber'
  },
  {
    title: 'devices.connectionLastMessageAt',
    value: 'connectionLastMessageAt',
    valueTransformer: (_, item: Device) =>
      dateFormatter.dateWithoutSeconds(item.connectionLastMessageAt)
  },
  {
    title: 'devices.connectionStartedAt',
    value: 'connectionStartedAt',
    valueTransformer: (_, item: Device) =>
      dateFormatter.dateWithoutSeconds(item.connectionStartedAt as string)
  }
];

export const deviceThreeDotMenuPatch = {
  id: ButtonCallbacks.TOGGLE,
  title: 'devices.actionButtonActions.threeDotMenuSmartUpdate'
};
