<template>
  <ExtendedDialog
    :show-dialog="show"
    :process-action="processing"
    :config="addGroupDialog"
    @execute-action="execute($event)"
  >
    <v-form
      ref="form"
      :disabled="processing"
      class="dialog-content"
      :validate-on="validationType"
      @keyup.enter="createGroup"
    >
      <v-text-field
        v-model="name"
        autofocus
        :rules="[rules.required, ruleUniqueGroupName]"
        :label="$t('common.groupName') + ' *'"
        variant="outlined"
        clearable
        density="compact"
        counter="50"
        @keydown.enter.prevent="createGroup"
      />
    </v-form>
  </ExtendedDialog>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'pinia';
import { deviceStore } from '@/store/pinia/DeviceStore';
import { DeviceGroup } from '@/store/types/inventory/Device';
import { ButtonCallbacks } from '@/components/types/Dialog';
import { addGroupDialog } from '@/models/inventory/deviceDialogConfig';
import ExtendedDialog from '@/components/common/dialog/ExtendedDialog.vue';
import { VALIDATION_TYPE } from '@/shared/constants';
import { rules } from '@/rules';

const DEFAULT_GROUP = 'Standardgruppe';

export default defineComponent({
  name: 'AddGroupDialog',
  components: { ExtendedDialog },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  emits: ['update:showDialog', 'setNewGroup'],
  data() {
    return {
      addGroupDialog,
      processing: false,
      name: '',
      rules,
      validationType: VALIDATION_TYPE.SUBMIT
    };
  },

  computed: {
    ...mapState(deviceStore, ['groups']),
    show: {
      get() {
        return this.showDialog;
      },
      set(value) {
        this.$emit('update:showDialog', value);
      }
    },
    nameRules() {
      return [
        (v: string) => !!v || this.$t('devices.nameRuleGroupRequired'),
        (v: string) =>
          !this.groups.map((group: DeviceGroup) => group.name).includes(v) ||
          this.$t('devices.nameRuleGroupRequiredUnique')
      ];
    }
  },
  mounted() {
    void this.fetchGroups();
  },
  methods: {
    ...mapActions(deviceStore, ['registerGroup', 'fetchGroups']),
    async execute(callbackID) {
      if (callbackID === ButtonCallbacks.ADD) {
        await this.createGroup();
      } else {
        this.closeDialog();
      }
    },
    ruleUniqueGroupName(v: string) {
      return (
        !this.groups.map((group: DeviceGroup) => group.name).includes(v) ||
        this.$t('devices.nameRuleGroupRequiredUnique')
      );
    },
    async createGroup() {
      const { valid } = await this.$refs.form.validate();
      this.validationType = VALIDATION_TYPE.INPUT;
      if (valid) {
        this.processing = true;
        await this.registerGroup(this.name);
        this.processing = false;
        this.closeDialog(this.name);
        await this.fetchGroups();
      }
    },
    closeDialog(newGroup?: string) {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.$emit('setNewGroup', newGroup ?? DEFAULT_GROUP);
      this.$emit('update:showDialog', false);
    }
  }
});
</script>
