<template>
  <v-card elevation="0" color="transparent">
    <v-container v-if="loading || processing">
      <v-col>
        <v-row justify="center">
          <v-progress-circular
            :size="60"
            :width="7"
            color="primary"
            indeterminate
          />
        </v-row>
        <v-row justify="center">{{ $t('common.wait') }}.</v-row>
      </v-col>
    </v-container>
    <v-container v-else data-cy="drop-zone" class="pa-0 ma-0">
      <v-row
        :style="customStyle"
        no-gutters
        align="center"
        class="dropzone"
        justify="center"
        @drop.prevent="dropFile"
        @dragover.prevent
      >
        <v-col>
          <v-row justify="center">
            <v-icon color="primary" size="x-large">mdi-cloud-upload</v-icon>
          </v-row>
          <v-row justify="center" class="pt-3 pb-2">
            {{ $t('resources.buttonUploadData') }}
          </v-row>
          <v-row justify="center">
            <v-btn
              class="text-none"
              color="primary"
              variant="text"
              @click="$refs.inputUpload.click()"
              >{{ $t('common.browse') }}</v-btn
            >
          </v-row>
          <input
            v-show="false"
            ref="inputUpload"
            type="file"
            :accept="fileTypes"
            :multiple="!!$attrs['multiple']"
            @change="inputFile"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { FileEventTarget } from '@/shared/upload';

export default defineComponent({
  name: 'FileUploader',

  emits: ['addFiles'],
  props: {
    customStyle: {
      type: String,
      default: 'height: 50vh'
    },
    fileTypes: {
      type: String,
      default: '*/*'
    },
    processing: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    dropFile(e: FileEventTarget) {
      this.loading = true;
      this.addFiles(e.dataTransfer.files);
    },
    inputFile(e: FileEventTarget) {
      this.loading = true;
      this.addFiles(e.target.files);
      e.target.value = null;
    },
    addFiles(files: FileList) {
      this.loading = false;
      this.$emit('addFiles', files);
    }
  }
});
</script>
<style scoped lang="scss">
@import '@/styles/colors.scss';
#upload-packets {
  padding-top: 0 !important;
}
.dropzone {
  border-style: dashed;
  border-width: 2px;
  border-color: $primary;
}
</style>
