import router from './router';
import { createApp } from 'vue';
import App from './App.vue';

import { vuetify } from './plugins/vuetify';
import store from './store';
import './styles/global.scss';

import { i18n, vuei18n } from '@/plugins/i18n';
import { directives } from './directives';
import { filters } from './filters';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
// New Imports
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from 'apollo-link-context';

// Hell yeah
import { createPinia } from 'pinia';
import { vueCookies } from '@/plugins/vue-cookies';

const pinia = createPinia();

import VueApollo from 'vue-apollo';
import { TokenService } from '@/services/auth/TokenService';

const getAuthToken = () => TokenService.getAccessToken();

const authLink = setContext((_, { headers }) =>
  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      Authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : ''
    }
  })
);

const httpLink = createHttpLink({
  uri: filters.getGraphqlUrlHTTP()
});

const wsLink = new WebSocketLink({
  uri: filters.getGraphqlUrlWS(),
  options: {
    reconnect: true,
    timeout: 30000,
    inactivityTimeout: 30000,
    lazy: true,
    connectionParams: {
      Authorization: getAuthToken()
    }
  }
});
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

// TODO: is needed?
apolloProvider;
// Install the vue plugin like before
// Vue.use(VueApollo);
// Vue.use(PiniaVuePlugin);

// Vue.use(VueCookies);
// // VueCookies.config('7d');

// Vue.config.productionTip = false;

// new Vue({
//   vuetify,
//   router,
//   store,
//   i18n,
//   pinia,
//   render: (h) => h(App)
// }).$mount('#app');

// import { configureCompat } from 'vue';

// // disable compat for certain features
// configureCompat({
//   COMPONENT_ASYNC: false,
//   COMPONENT_V_MODEL: false
// });

export const app = createApp(App)
  .use(vuetify)
  .use(vuei18n)
  .use(router)
  .use(store)
  .use(pinia)
  .use(vueCookies);

import VueEventer from 'vue-eventer';
export const eventBus = new VueEventer();

export const applyGlobalsToComponent = (comp: typeof app) => {
  comp.config.globalProperties.$eventBus = eventBus;

  Object.entries(filters).forEach(([name, filter]) => {
    //   Vue.filter(name, filter);
    if (!comp.config.globalProperties.$filters) {
      comp.config.globalProperties.$filters = {};
    }
    comp.config.globalProperties.$filters[name] = filter;
  });

  Object.entries(directives).forEach(([name, directive]) => {
    //   Vue.directive(name, directive);
    if (!comp.config.globalProperties.$directives) {
      comp.config.globalProperties.$directives = {};
    }
    comp.config.globalProperties.$directives[name] = directive;
  });

  comp.provide('$router', router); // hack! globally inject router into every component
  comp.config.globalProperties.$router = router; // hack! globally inject router

  // only required for dialogUtil as somehow `$t` and `$tc` get lost when using the (shitty) dialogUtil
  comp.provide('$t', i18n.t); // hack!
  comp.config.globalProperties.$t = i18n.t; // hack!
  comp.provide('$tc', i18n.tc); // hack!
  comp.config.globalProperties.$tc = i18n.tc; // hack!
};

applyGlobalsToComponent(app);

export const appCompost = app.mount('#app');
