import { FeatureFlags } from '../store/types/auth/index';
export type NavigationItem = {
  title: string;
  icon?: string;
  featureFlags?: keyof FeatureFlags | (keyof FeatureFlags)[];
} & ({ path: string; children?: never } | { children: NavigationItem[]; path?: never });

export const navigationItemVisible = (flagConditions: NavigationItem['featureFlags'], featureFlags: FeatureFlags): boolean => {
  if (!flagConditions) {
    return true;
  }
  if (typeof flagConditions === 'string') {
    flagConditions = [flagConditions];
  }
  return flagConditions.some((flagCondition) => featureFlags[flagCondition]); // Geht nur für OR
};

export const navigationDrawerConfig: NavigationItem[] = [
  { title: 'routes.dashboard', path: '/dashboard', icon: 'mdi-view-dashboard' },
  {
    title: 'routes.inventory', icon: 'mdi-package-variant-closed',
    children: [
      { title: 'routes.routers', path: '/devices' },
      { title: 'routes.configChangeTemplates', path: '/config-change-templates',
        featureFlags: 'cliTemplatesEnabled' }
    ]
  },
  {
    title: 'common.updates', icon: 'mdi-update',
    featureFlags: ['autoUpdateServerEnabled', 'updateJobsEnabled'],
    children: [
      { title: 'routes.autoUpdateServer', path: '/auto-update-server', featureFlags: 'autoUpdateServerEnabled' },
      { title: 'routes.updateJobs', path: '/update-jobs', featureFlags: 'updateJobsEnabled' },
      { title: 'routes.updatePackages', path: '/update-packages', featureFlags: 'updatePacketManagementEnabled' },
      { title: 'common.resources', path: '/resources', featureFlags: 'updatePacketManagementEnabled' }
    ]
  },
  {
    title: 'routes.certificate-management', icon: 'mdi-shield-key',
    featureFlags: 'certificateManagementEnabled',
    children: [
      { title: 'routes.certificate-authorities', path: '/certificate-authorities' },
      { title: 'routes.certificates', path: '/certificates' }
    ]
  },
  {
    title: 'routes.settings', icon: 'mdi-cog',
    children: [
      { title: 'settings.userProfile', path: '/settings/user-profile' },
      { title: 'settings.accountSettings', path: '/settings/account-settings' },
      { title: 'settings.customField', path: '/settings/custom-fields', featureFlags: 'customFieldsEnabled' },
      { title: 'settings.apiToken', path: '/settings/api-token', featureFlags: 'apiEnabled' },
      { title: 'settings.licenses', path: '/settings/licenses' }
    ]
  },
  { title: 'routes.auditLogs', path: '/audit-logs', icon: 'mdi-text-box-search', featureFlags: 'auditLogEnabled' },
  {
    title: 'administration.administration', icon: 'mdi-wrench', featureFlags: 'userManagementEnabled',
    children: [
      { title: 'administration.systemInfo', path: '/administration/system-information' },
      { title: 'administration.userAndAccountManagement', children: [
        { title: 'administration.accounts', path: '/administration/user-and-account-management/accounts', icon: 'mdi-bank' },
        { title: 'common.user', path: '/administration/user-and-account-management/users', icon: 'mdi-account-multiple' },
        { title: 'administration.organisation', path: '/administration/user-and-account-management/organisations', icon: 'mdi-factory' }
      ] },
      { title: 'administration.systemSettings', path: '/administration/system-settings' }
    ]
  }
];
