<template>
  <DialogSmall ref="dialog" :title="title" dialog-width="90vh">
    <v-card
      v-if="!alert"
      elevation="0"
      class="ma-0 px-4 py-4 mb-4"
      color="transparent"
    >
      <v-form ref="form">
        <v-container>
          <v-text-field
            v-if="deployment.targetType !== DEPLOYMENT_TARGET_TYPE_ALL"
            id="cli-table-search-field"
            v-model="searchTerm"
            class="pb-3"
            :placeholder="$t('templates.searchValueTable')"
            prepend-inner-icon="mdi-magnify"
            variant="filled"
            rounded
            density="compact"
            single-line
            hide-details
          ></v-text-field>
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :sort-options="{
              enabled: false
            }"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
          >
            <template #table-row="props">
              <v-flex v-if="props.column.field !== 'insysIcomTargetName'">
                <v-text-field
                  v-if="props.column.type === 'text'"
                  :id="
                    'cli-table-textfield-' +
                    props.column.field +
                    '-' +
                    props.index
                  "
                  v-model="props.row[props.column.field]"
                  variant="solo"
                  flat
                  density="compact"
                  single-line
                  hide-details
                  class="mb-n2 mt-n2 pa-0"
                  @change="
                    changeCell(
                      props.row[props.column.field],
                      props.column.field,
                      props.row.originalIndex
                    )
                  "
                ></v-text-field>

                <v-checkbox
                  v-if="props.column.type === 'boolean'"
                  :id="
                    'cli-table-checkbox-' +
                    props.column.field +
                    '-' +
                    props.index
                  "
                  v-model="props.row[props.column.field]"
                  color="primary"
                  density="compact"
                  hide-details
                  class="pt-0 mt-0"
                  @update:model-value="
                    changeCell(
                      props.row[props.column.field],
                      props.column.field,
                      props.row.originalIndex
                    )
                  "
                  @click.stop.prevent
                >
                </v-checkbox>

                <v-text-field
                  v-if="props.column.type === 'number'"
                  :id="
                    'cli-table-numberfield-' +
                    props.column.field +
                    '-' +
                    props.index
                  "
                  v-model.number="props.row[props.column.field]"
                  variant="solo"
                  flat
                  density="compact"
                  single-line
                  hide-details
                  class="mb-n2 mt-n2 pa-0"
                  type="number"
                  @change="
                    changeCell(
                      props.row[props.column.field],
                      props.column.field,
                      props.row.originalIndex
                    )
                  "
                ></v-text-field>
              </v-flex>
            </template>
          </vue-good-table>
        </v-container>
      </v-form>
    </v-card>

    <v-container v-if="alert && !loading">
      <v-row no-gutters align="center" justify="center">
        <v-col class="justify-self">
          <v-row no-gutters align="center" justify="center">
            <v-alert variant="text" density="compact" type="error"
              >{{ $t('editDeployment.processFailed') }}
            </v-alert>
            <v-card-text align="center">
              <div>{{ $t('editDeployment.processFailed') }}</div>
              <div>{{ alertText }}</div>
              <v-btn
                class="text-none"
                color="primary"
                variant="text"
                @click="save"
                >{{ $t('editDeployment.tryAgain') }}
              </v-btn>
            </v-card-text>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <v-card-actions class="pa-0 ma-0">
      <v-layout row class="pa-0 ma-0">
        <v-flex
          v-if="loading"
          grow
          class="d-flex align-center pl-4 warning-card"
        >
          <v-progress-circular
            id="edit-subject-loading"
            indeterminate
            size="32"
            width="4"
            color="black"
          ></v-progress-circular>
          <span class="pl-4">{{ loadingText }}</span>
        </v-flex>
        <v-flex
          class="d-flex justify-end pt-3 pb-3 pl-6 pr-4"
          :class="{ shrink: loading }"
        >
          <v-btn
            id="edit-image-cancel-button"
            variant="outlined"
            color="primary"
            class="text-none pl-4 pr-4 mx-1"
            :disabled="loading"
            @click="closeDialog"
            >{{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            id="edit-image-save-button"
            variant="flat"
            color="primary"
            class="text-none pl-4 pr-4 mx-1"
            @click="save"
          >
            {{ $t('common.save') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-actions>
  </DialogSmall>
</template>

<script lang="ts">
/* eslint-disable max-lines */

import { defineComponent, App } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import {
  DEPLOYMENT_METHOD_AUTO_UPDATE,
  DEPLOYMENT_METHOD_UPDATE_JOB,
  DEPLOYMENT_TARGET_TYPE_ALL
} from '@/models/rollouts/constants';
import { SubjectType, TargetType } from '@/shared/updateWizardSteps';
import { VueGoodTable } from 'vue-good-table-next';
import {
  CliTemplateType,
  DeploymentTypeCliTemplate,
  Device
} from '@/store/types';
import DialogSmall from '@/components/common/DialogSmall.vue';
import { DeviceGroup } from '../../../store/types/inventory/Device';

interface ColumnType {
  label: string;
  field: string;
  type: string;
}

export default defineComponent({
  name: 'EditTemplateDialog',
  components: {
    DialogSmall,
    VueGoodTable
  },
  props: {
    deployment: {
      type: Object as () => DeploymentTypeCliTemplate,
      required: true
    },
    title: String,
    individualId: String
  },
  data() {
    return {
      DEPLOYMENT_TARGET_TYPE_ALL: DEPLOYMENT_TARGET_TYPE_ALL,
      loading: false,
      alert: false,
      FirmwareOrSoftware: SubjectType.USER_UPDATE_PACKETS,
      alertText: '',
      commonValues: '',
      targetValues: '',
      commonValuesRules: [
        (v: string) => !!v || this.$t('editDeployment.commonValuesRequired'),
        (v: string) => {
          try {
            JSON.parse(v);
          } catch (e) {
            return this.$t('editDeployment.commonValuesNeedsToBeJSON');
          }
          return true;
        }
      ],
      targetValuesRules: [
        (v: string) => !!v || this.$t('editDeployment.targetValuesRequired'),
        (v: string) => {
          try {
            JSON.parse(v);
          } catch (e) {
            return this.$t('editDeployment.targetValuesNeedsToBeJSON');
          }
          return true;
        }
      ],
      searchTerm: '',
      rows: []
    };
  },
  mounted() {
    this.fillRows();
  },
  computed: {
    // ...mapState(deviceStore,['getDeviceById', 'getGroupById']),
    ...mapGetters({
      templateById: 'templates/templateById'
    }),
    template(): CliTemplateType {
      return this.templateById(this.deployment.cliTemplate.uuid);
    },
    loadingText(): string {
      if (this.deployment.method === DEPLOYMENT_METHOD_AUTO_UPDATE) {
        return `${this.$t('editDeployment.refreshUpdateServer')}`;
      } else {
        return `${this.$t('editDeployment.refreshUpdateJob')}`;
      }
    },
    dialog(): App & { close: () => void } {
      return this.$refs.dialog as App & { close: () => void };
    },
    form(): App & { validate: () => boolean } {
      return this.$refs.form as App & { validate: () => boolean };
    },
    columns(): ColumnType[] {
      const retVal: ColumnType[] = [];

      switch (this.deployment.targetType) {
        case TargetType.TYPE_MANAGED_DEVICES: {
          retVal.push({
            label: `${this.$t('common.device')}`,
            field: 'insysIcomTargetName',
            type: 'string'
          });
          break;
        }
        case TargetType.TYPE_DEVICE_GROUPS: {
          retVal.push({
            label: `${this.$tc('common.group', 1)}`,
            field: 'insysIcomTargetName',
            type: 'string'
          });
          break;
        }
        default: {
          // No target for all
          break;
        }
      }
      this.template?.keysConfiguration.forEach((keysConfigurationKey) => {
        let valueType = keysConfigurationKey.valueType;
        // Table doesn't know type string --> equals type text
        if (valueType === 'string') {
          valueType = 'text';
        }
        retVal.push({
          label: keysConfigurationKey.key,
          type: valueType,
          field: keysConfigurationKey.key
        });
      });

      return retVal;
    }
  },
  methods: {
    ...mapActions({
      loadUpdatePackets: 'updatePackages/loadUpdatePackets',
      updateType: 'editDeploymentSubject/updateType',
      setUpdatePacketId: 'editDeploymentSubject/setUpdatePacketId',
      getUpdatePacketFromServer:
        'editDeploymentSubject/getUpdatePacketFromServer',
      updateDeployment: 'updateServer/update',
      refreshServers: 'updateServer/load',
      refreshUpdateJobs: 'updateJobs/load',
      reset: 'editDeploymentSubject/reset',
      updateAlertNoPacket: 'editDeploymentSubject/updateAlertNoPacket'
    }),
    fillArray(targetArray: (Device | DeviceGroup)[], targetType: string) {
      if (targetType === TargetType.TYPE_MANAGED_DEVICES) {
        this.deployment.target.forEach((targetId) => {
          const device = this.getDeviceById(targetId);
          if (device) {
            targetArray.push(device);
          }
        });
        return targetArray;
      } else {
        this.deployment.target.forEach((targetId) => {
          const group = this.getGroupById(targetId);
          if (group) {
            targetArray.push(group);
          }
        });
        return targetArray;
      }
    },
    fillRows() {
      switch (this.deployment.targetType) {
        case TargetType.TYPE_MANAGED_DEVICES: {
          const targets: Device[] = [];
          this.fillArray(targets, TargetType.TYPE_MANAGED_DEVICES);
          this.addRowsForTargets(targets);

          break;
        }
        case TargetType.TYPE_DEVICE_GROUPS: {
          const targets: DeviceGroup[] = [];
          this.fillArray(targets, TargetType.TYPE_DEVICE_GROUPS);
          this.addRowsForTargets(targets);

          break;
        }
        case TargetType.TYPE_ALL: {
          const row = {};

          this.columns.forEach((column) => {
            if (this.deployment.cliTemplate.commonValues[column.field]) {
              row[column.field] =
                this.deployment.cliTemplate.commonValues[column.field];
            } else {
              if (column.type === 'boolean') {
                row[column.field] = false;
              } else {
                row[column.field] = '';
              }
            }
          });

          // No targets here
          this.rows.push(row);
          break;
        }
        default: {
          break;
        }
      }
    },
    addRowsForTargets: function (targets: DeviceGroup[]) {
      /*
      Add rows with every field as attribute + name and uuid of the target
      e.g.:
      {
        hostname: '',
        ip: '',
        ping: ''
      }
      expanded to
      {
        hostname: '',
        ip: '',
        ping: '',
        insysIcomTargetName: device.name,
        uuid: device.uuid
       }
       */
      targets.forEach((target) => {
        const row = {} as Record<string, unknown>;

        // fill with values if already in target values
        const targetValues =
          this.deployment.cliTemplate.targetValues[target.uuid];

        this.columns.forEach((column) => {
          if (targetValues[column.field]) {
            row[column.field] = targetValues[column.field];
          } else {
            if (column.type === 'boolean') {
              row[column.field] = false;
            } else {
              row[column.field] = '';
            }
          }
        });
        row.insysIcomTargetName = target?.name;
        row.uuid = target?.uuid;

        this.rows.push(row);
      });
    },
    changeCell(changedData, column, row) {
      // Change cell in rows
      this.rows[row][column] = changedData;
    },
    closeDialog(): void {
      this.dialog.close();
      this.reset();
    },
    save() {
      this.$nextTick(async () => {
        if (this.form.validate()) {
          this.alert = false;
          this.loading = true;
          try {
            if (this.deployment.method === DEPLOYMENT_METHOD_UPDATE_JOB) {
              await this.updateUpdateJob();
            } else if (
              this.deployment.method === DEPLOYMENT_METHOD_AUTO_UPDATE
            ) {
              await this.updateAutoUpdate();
            }
            this.closeDialog();
          } catch (err) {
            this.alert = true;
            this.alertText = err;
          }
        }
      });
    },
    async updateAutoUpdate() {
      try {
        await this.updateDeployment({
          deploymentId: this.deployment.uuid,
          body: {
            name: this.deployment.name,
            description: this.deployment.description,
            targetType: this.deployment.targetType,
            subject: this.deployment.subject,
            method: this.deployment.method,
            target: this.deployment.target,
            autoUpdate: this.deployment.autoUpdate,
            cliTemplate: this.getCliTemplate()
          }
        });
        await this.refreshServers();
        this.closeDialog();
      } catch (err) {
        this.alert = true;
        this.alertText = err;
      } finally {
        this.loading = false;
      }
    },
    async updateUpdateJob() {
      try {
        await this.updateDeployment({
          deploymentId: this.deployment.uuid,
          body: {
            name: this.deployment.name,
            description: this.deployment.description,
            targetType: this.deployment.targetType,
            subject: this.deployment.subject,
            method: this.deployment.method,
            target: this.deployment.target,
            updateJob: this.deployment.updateJob,
            cliTemplate: this.getCliTemplate()
          }
        });
        await this.refreshUpdateJobs();
        this.closeDialog();
      } catch (err) {
        this.alert = true;
        this.alertText = err;
      } finally {
        this.loading = false;
      }
    },
    getCliTemplate() {
      const template = this.deployment.cliTemplate;

      const targetValues = {};
      let commonValues = {};

      if (this.deployment.targetType !== TargetType.TYPE_ALL) {
        this.rows.forEach((row) => {
          // uuid is needed for target values
          const uuid = row.uuid;
          delete row.uuid;
          delete row.insysIcomTargetName;
          targetValues[uuid] = row;
        });
      } else {
        // Put everything into common values for Target type ALL
        // No targets needed
        commonValues = this.rows[0];
      }

      if (Object.keys(commonValues)?.length) {
        template.commonValues = commonValues;
      }

      if (Object.keys(targetValues)?.length) {
        template.targetValues = targetValues;
      }

      return template;
    }
  }
});
</script>

<!-- Style for table -->
<style lang="scss">
@import './src/styles/vue-good-table/style.scss';
@import '@/styles/colors.scss';

.vgt-inner-wrap {
  //remove completely
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.vgt-table {
  thead th {
    background: $greyish-table-background !important;
  }
}

table.vgt-table td {
  padding: 0.5em 0.5em 0.5em 0.5em !important;
}
</style>
